<template>
  <div class="detail">
    <!-- 面包屑导航 -->

    <div class="bread-box">
      <div>当前位置：</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <span style="cursor: pointer;" @click="goBack">{{ name }}</span>
        <i class="el-icon-arrow-right" style="margin: 0 5px;"></i>
        <span>{{ info.car_name }}</span>
      </el-breadcrumb>
    </div>
    <!-- 顶部 -->
    <div class="detail-top">
      <div class="detail-top-left">
        <div class="lens">
          <Lens :url="lesUrl"></Lens>
        </div>
        <div class="banner">
          <Banner :list="info.exterior_images" @changeUrl="changeUrl"></Banner>
        </div>
      </div>
      <div class="detail-top-right">
        <span class="detail-i-title">{{ info.car_name }}</span>
        <div class="detail-base-info">
          <div
            class="detail-base-item detail-base-items"
            v-for="(item, index) in basesList"
            :key="index"
          >
            <span class="detail-base-item-title detail-base-item-titles">{{
              item.title ? item.title : "-"
            }}</span>
            <span class="detail-base-item-name">{{ item.name }}</span>
          </div>
        </div>
        <div class="detail-base-coll">
          <div class="detail-base-coll-left">
            <span>到手价</span>
            <span>{{ info.price }}万</span>
          </div>
          <el-button
            type="primary"
            :icon="info.is_collect == 1 ? 'el-icon-star-on' : 'el-icon-star-off'"
            style="padding: 12px 40px"
            @click="handleCollect(info.id)"
            >{{ info.is_collect == 1 ? "已收藏" : "收藏" }}</el-button
          >
        </div>
        <div class="detail-user">
          <span>定金{{ info.down_payment_price }}万</span>
          <!-- <span>联系电话：{{ store.phone }}</span> -->
          <!-- <span>联系电话：*********</span> -->
        </div>
        <div class="detail-btn">
          <el-button
            type="primary"
            @click="handleAppoint"
            style="padding: 12px 40px; margin-right: 20px"
            >预约看车</el-button
          >
          <el-button
            type="primary"
            @click="handleBargain"
            style="padding: 12px 40px; margin-right: 20px"
            >我要砍价</el-button
          >
          <el-button
            type="primary"
            @click="handlePayInfo"
            style="padding: 12px 40px; margin-right: 20px"
            >支付定金</el-button
          >
        </div>
      </div>
    </div>
    <!-- 基本信息 -->
    <div class="detail-base">
      <span class="detail-title">基本信息</span>
      <div class="detail-base-info" style="justify-content: center;">
        <div class="detail-base-item" v-for="(item, index) in baseList" :key="index">
          <span class="detail-base-item-title">{{ item.title ? item.title : "-" }}</span>
          <span class="detail-base-item-name">{{ item.name }}</span>
        </div>
      </div>
      <span class="detail-fu">基本参数</span>
      <ul>
        <li v-for="(item, index) in infoList" :key="index">
          <span class="detail-fu-title">{{ item.title }}</span>
          <span class="detail-fu-name">{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <!-- 车辆图片 -->
    <div class="detail-mid">
      <span class="detail-title">车辆图片</span>
      <span class="detail-car-title"
        >车辆外观({{ info.exterior_images ? info.exterior_images.length : 0 }})</span
      >
      <ul>
        <li v-for="(item, index) in info.exterior_images" :key="index">
          <el-image style="width: 712px; height: 474px" :src="item" fit="contain"></el-image>
        </li>
      </ul>
      <span class="detail-car-title" v-if="info.trim_images && info.trim_images.length > 0"
        >车辆内饰({{ info.trim_images ? info.trim_images.length : 0 }})</span
      >
      <ul v-if="info.trim_images && info.trim_images.length > 0">
        <li v-for="(item, index) in info.trim_images" :key="index">
          <el-image style="width: 712px; height: 474px" :src="item" fit="contain"></el-image>
        </li>
      </ul>

      <span class="detail-car-title" v-if="info.engine_images && info.engine_images.length > 0"
        >车辆底盘({{ info.engine_images ? info.engine_images.length : 0 }})</span
      >
      <ul v-if="info.engine_images && info.engine_images.length > 0">
        <li v-for="(item, index) in info.engine_images" :key="index">
          <el-image style="width: 712px; height: 474px" :src="item" fit="contain"></el-image>
        </li>
      </ul>
    </div>
    <!-- 更多二手车 -->
    <div class="detail-er">
      <span class="detail-title" style="margin-bottom: 20px">更多二手车</span>
      <!-- 内容 -->
      <div class="home-hot-content">
        <div
          class="home-hot-item"
          v-for="(item, index) in dataList"
          :key="index"
          @click="goToDetail(item.id)"
        >
          <div class="home-hot-item-content">
            <el-image
              style="width: 334px; height: 232px"
              :src="item.exterior_images[0]"
              fit="contain"
            ></el-image>
            <div class="neis">
              <span class="neis-title">{{ item.car_name }}</span>
              <div class="neis-time">
                <span style="margin-right: 5px">{{ item.license_tag }}年</span>
                |
                <span style="margin-left: 5px">{{ item.show_mileage }}万公里</span>
              </div>
              <div class="neis-pice">
                <span style="font-size: 18px">{{ item.price }}</span
                >万
                <span style="margin-left: 5px">首付{{ item.down_payment_price }}万</span>
              </div>
            </div>
          </div>
        </div>

        <el-empty description="暂无车辆信息~" v-if="dataList.length == 0"></el-empty>
      </div>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </div>
    <!-- 弹窗 -->
    <!-- 我要预约 -->
    <el-dialog
      title="我要预约"
      :visible.sync="appointVisible"
      width="500px"
      append-to-body
      :before-close="handleCloseAppoint"
    >
      <AppointForm v-if="appointVisible" :id="id" @closeAppoint="handleCloseAppoint"></AppointForm>
    </el-dialog>
    <!-- 我要砍价 -->
    <el-dialog
      title="我要砍价"
      :visible.sync="bargainVisible"
      width="500px"
      append-to-body
      :before-close="handleCloseBargain"
    >
      <BargainForm v-if="bargainVisible" :id="id" @closeBargain="handleCloseBargain"></BargainForm>
    </el-dialog>
    <!-- 支付定金 -->
    <el-dialog
      title="支付定金"
      :visible.sync="infoVisible"
      width="1400px"
      append-to-body
      :before-close="handleCloseInfo"
    >
      <BuyDetail :info="info" v-if="infoVisible"></BuyDetail>
    </el-dialog>
  </div>
</template>

<script>
import { indexCarDetail, recommend, indexCollect } from "@/api";
import AppointForm from "./components/appointForm.vue";
import BuyDetail from "./components/buyDetail.vue";
import BargainForm from "./components/bargainForm.vue";
import Banner from "./components/banner.vue";
import Lens from "./components/lens.vue";
export default {
  components: {
    BuyDetail,
    AppointForm,
    BargainForm,
    Lens,
    Banner,
  },
  data() {
    return {
      lesUrl: "",
      id: "",
      name: '',
      appointVisible: false,
      bargainVisible: false,
      infoVisible: false,
      info: {},
      store: {},
      basesList: [
        {
          title: "",
          name: "上牌时间",
        },
        {
          title: "",
          name: "表显里程",
        },
        {
          title: "",
          name: "排量",
        },
        {
          title: "",
          name: "变速箱",
        },
      ],
      baseList: [
        {
          title: "",
          name: "首次上牌",
        },
        {
          title: "",
          name: "表显里程",
        },
        {
          title: "",
          name: "排放标准",
        },
        {
          title: "",
          name: "新车含税价",
        },
        {
          title: "",
          name: "过户次数",
        },
        // {
        //   title: "",
        //   name: "总功率",
        // },
        {
          title: "",
          name: "车牌归属地",
        },
        // {
        //   title: "",
        //   name: "续航里程",
        // },
        // {
        //   title: "",
        //   name: "电池容量",
        // },
        {
          title: "",
          name: "车身颜色",
        },
        {
          title: "",
          name: "出厂日期",
        },
        {
          title: "",
          name: "钥匙数量",
        },
      ],
      infoList: [
        {
          title: "厂商",
          name: "",
        },
        {
          title: "上市时间",
          name: "",
        },
        {
          title: "工信部综合油耗(L/100km)",
          name: "",
        },
        {
          title: "生产方式",
          name: "",
        },
        {
          title: "能源形式",
          name: "",
        },
        {
          title: "车身形式",
          name: "",
        },
        {
          title: "车商指导价(万元)",
          name: "",
        },
        {
          title: "变速箱",
          name: "",
        },
        {
          title: "整车质保(生产厂商)",
          name: "",
        },
      ],
      current: 1,
      size: 12,
      total: 1,
      dataList: [],
    };
  },
  // created() {
  //   if (this.$route.query.id !== undefined) {
  //     this.id = this.$route.query.id;
  //     this.name = this.$route.query.name
  //     this.getIndexCarDetail();
  //   }
  //   this.getIndexCarList();
  // },
  activated() {
    // 当组件被激活时，重新获取数据
    // this.fetchData = this.fetchDataFromAPI();
    if (this.$route.query.id !== undefined) {
      this.id = this.$route.query.id;
      this.name = this.$route.query.name
      this.getIndexCarDetail();
    }
    this.getIndexCarList();
  },
  methods: {
    // 返回原来页面
    goBack(){
      this.$router.go(-1);
    },
    // 收藏取消收藏
    handleCollect(car_id) {
      if (window.localStorage.getItem("userInfo")) {
        indexCollect({
          u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
          car_id: car_id,
        }).then((res) => {
          this.$message.success(res.msg);
          this.getIndexCarDetail();
        });
      } else {
        this.$router.push({
          path: "/login",
        });
      }
    },
    // 前往详情页面

    goToDetail(id) {
      // 构建URL，包含路径和查询参数
      const url = this.$router.resolve({
        path: "/detail",
        query: {
          id: id,
          name: '详情'
        },
      }).href;

      // 使用window.open打开新页面
      window.open(url, "_blank");
    },
    // 切换图片
    changeUrl(url) {
      this.lesUrl = url;
    },
    // 获取详情
    getIndexCarDetail() {
      indexCarDetail({
        u_id: window.localStorage.getItem("userInfo")
          ? JSON.parse(window.localStorage.getItem("userInfo")).id
          : 0,
        id: this.id,
      }).then((res) => {
        let info = res.data;
        this.info = info;
        this.store = info.store;
        this.lesUrl = info.exterior_images[0];
        let basesList = [
          {
            title: info.license_tag,
            name: "上牌时间",
          },
          {
            title: info.show_mileage,
            name: "表显里程",
          },
          {
            title: info.discharge + (info.discharge_unit == 1 ? "L" : "T"),
            name: "排量",
          },
          {
            title: info.gearbox,
            name: "变速箱",
          },
        ];
        this.basesList = basesList;
        let baseList = [
          {
            title: info.license_tag,
            name: "首次上牌",
          },
          {
            title: info.show_mileage,
            name: "表显里程",
          },
          {
            title: info.let_out,
            name: "排放标准",
          },
          {
            title: info.tax_price,
            name: "新车含税价",
          },
          {
            title: info.transfer_count,
            name: "过户次数",
          },
          // {
          //   title: "",
          //   name: "总功率",
          // },
          {
            title: info.licence_plate,
            name: "车牌归属地",
          },
          // {
          //   title: "",
          //   name: "续航里程",
          // },
          // {
          //   title: "",
          //   name: "电池容量",
          // },
          {
            title: info.color,
            name: "车身颜色",
          },
          {
            title: info.leave_factory,
            name: "出厂日期",
          },
          {
            title: info.key_count,
            name: "钥匙数量",
          },
        ];
        this.baseList = baseList;
        let infoList = [
          {
            title: "厂商",
            name: info.manufacturer,
          },
          {
            title: "上市时间",
            name: info.time_to_market,
          },
          {
            title: "工信部综合油耗(L/100km)",
            name: info.oil_wear,
          },
          {
            title: "生产方式",
            name: info.production_mode,
          },
          {
            title: "能源形式",
            name: info.energy_modality,
          },
          {
            title: "车身形式",
            name: info.car_body,
          },
          {
            title: "车商指导价(万元)",
            name: info.guide_price,
          },
          {
            title: "变速箱",
            name: info.gearbox,
          },
          {
            title: "整车质保(生产厂商)",
            name: info.quality_guarantee,
          },
        ];
        this.infoList = infoList;
      });
    },
    // 支付定金
    handlePayInfo() {
      if (window.localStorage.getItem("userInfo")) {
        this.infoVisible = true;
      } else {
        this.$router.push({
          path: "/login",
        });
      }
    },
    // 关闭支付定金弹窗
    handleCloseInfo() {
      this.infoVisible = false;
    },
    // 预约看车
    handleAppoint() {
      if (window.localStorage.getItem("userInfo")) {
        this.appointVisible = true;
      } else {
        this.$router.push({
          path: "/login",
        });
      }
    },
    // 关闭预约看车弹窗
    handleCloseAppoint() {
      this.appointVisible = false;
    },
    // 我要砍价
    handleBargain() {
      if (window.localStorage.getItem("userInfo")) {
        this.bargainVisible = true;
      } else {
        this.$router.push({
          path: "/login",
        });
      }
    },
    // 关闭我要砍价弹窗
    handleCloseBargain() {
      this.bargainVisible = false;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getIndexCarList();
    },
    // 获取首页车辆数据
    getIndexCarList() {
      recommend({
        u_id: window.localStorage.getItem("userInfo")
          ? JSON.parse(window.localStorage.getItem("userInfo")).id
          : 0,
        page: this.current,
        limit: this.size,
      }).then((res) => {
        if (res.code == 1) {
          this.dataList = res.data.data;
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  width: 1448px;
  margin: 0 auto;
  .bread-box{
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 14px;
    color: #F18700;
    /deep/.el-breadcrumb{
      line-height: normal !important;
      span{
        color: #F18700 !important;
      }
      .el-breadcrumb__separator{
        color: #F18700 !important;
      }
    }
  }
  .detail-title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    display: block;
    border-bottom: 1px solid #ccc;
    line-height: 40px;
  }
  .detail-base-info {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .detail-base-item {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 20px;
      position: relative;
      &::after {
        content: "";
        display: inline-block;
        width: 1px; /* 竖线的宽度 */
        height: 35px; /* 竖线的高度，根据需要调整 */
        background-color: #adb0b7; /* 竖线的颜色 */
        position: absolute; /* 绝对定位，相对于父元素 */
        right: 0; /* 竖线的位置，可以根据需要调整 */
      }
      .detail-base-item-title {
        font-size: 16px;
        line-height: 24px;
      }
      .detail-base-item-name {
        font-size: 12px;
        line-height: 24px;
        color: #adb0b7;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    .detail-base-items {
      padding: 0 30px;
      .detail-base-item-titles {
        font-weight: bold;
      }
    }
  }
  .detail-top {
    width: 100%;
    height: 720px;
    box-sizing: border-box;
    padding: 0;
    display: flex;
    justify-content: space-between;
    .detail-top-left {
      width: 726px;
      height: 100%;
      margin-right: 46px;
      flex-shrink: 0;
      .lens {
        width: 726px;
        height: 484px;
      }
      .banner {
        width: 726px;
        height: 92px;
        margin: 21px auto 0;
      }
    }
    .detail-top-right {
      width: 676px;
      height: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      .detail-i-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 60px;
        text-align: left;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      .detail-base-coll {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .detail-base-coll-left {
          display: flex;
          flex-direction: column;
          span {
            &:first-child {
              font-size: 14px;
            }
            &:last-child {
              font-size: 20px;
              color: #f18700;
              margin-top: 5px;
            }
          }
        }
      }
      .detail-user {
        width: 100%;
        height: 60px;
        background: #f1f9f0;
        margin-top: 30px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0 20px;
        span {
          text-align: left;
          flex: 1;
          display: flex;
          align-items: center;
          font-size: 16px;
          &:first-child {
            // border-bottom: 1px solid #ccc;
            color: #f18700;
          }
          &:last-child {
            color: red;
          }
        }
      }
      .detail-btn {
        width: 100%;
        height: 60px;
        margin-top: 80px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
  .detail-base {
    .detail-fu {
      display: block;
      font-size: 18px;
      height: 40px;
      width: 100%;
      text-align: center;
      line-height: 40px;
      border: 1px solid #dadde5;
      background: #f3f2f2;
      margin-bottom: 3px;
    }
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      list-style: none;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      li {
        box-sizing: border-box;
        width: 32%;
        height: 40px;
        margin-right: 2%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-right: 1px solid #dadde5;
        border-left: 1px solid #dadde5;
        border-bottom: 1px solid #dadde5;
        .detail-fu-title {
          width: 200px;
          height: 100%;
          font-size: 14px;
          text-align: center;
          line-height: 40px;
          flex-shrink: 0;
          border-right: 1px solid #dadde5;
        }
        .detail-fu-name {
          flex: 1;
          height: 100%;
          font-size: 14px;
          text-align: center;
          line-height: 40px;
        }
        &:nth-child(3n) {
          margin: 0;
        }
        &:nth-child(1) {
          border-top: 1px solid #dadde5;
        }
        &:nth-child(2) {
          border-top: 1px solid #dadde5;
        }
        &:nth-child(3) {
          border-top: 1px solid #dadde5;
        }
      }
    }
  }
  .detail-mid {
    width: 100%;
    .detail-car-title {
      font-size: 16px;
      display: block;
      padding: 15px 0;
    }
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      list-style: none;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      li {
        width: 712px;
        height: 474px;
        margin-right: 24px;
        margin-bottom: 24px;
        &:nth-child(2n) {
          margin: 0;
        }
      }
    }
  }
}
</style>
