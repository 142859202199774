<template>
  <div class="x-off-top">
    <div class="x-off-top-nei">
      <!-- logo -->
      <div class="x-off-img">
        <el-image
          style="width: 172px; height: 82px"
          :src="require('@/assets/logo.png')"
          fit="contain"
        ></el-image>
      </div>
      <!-- 导航 -->
      <div class="x-off-nav">
        <el-menu
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          background-color="#fff"
          text-color="#000"
          active-text-color="#F18700"
          @select="handleSelect"
          router
        >
          <!-- 动态不能加active 在permission.js的whiteList加上对应路径 -->
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/buy">我要买车</el-menu-item>
          <el-menu-item index="/sell">我要卖车</el-menu-item>
          <el-menu-item index="/host">我要入驻</el-menu-item>
        </el-menu>
      </div>
      <!-- 右侧区域 -->
      <div class="x-off-info">
        <div v-if="isShowInfo">
          <img
            class="top-bar__img"
            src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            @click="gotoUser"
          />
          <el-dropdown class="x-off-user">
            <span class="el-dropdown-link" @click="gotoUser">
              {{ userInfo.username }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleLogOut"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="nav" v-else>
          <router-link to="/login">快速注册</router-link> |
          <router-link to="/login">登录</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validatenull } from "@/common/common.js";
export default {
  data() {
    return {
      activeIndex: "1",
      userInfo: {},
      isShowInfo: false,
    };
  },
  created() {
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    this.isShowInfo = !validatenull(
      JSON.parse(window.localStorage.getItem("userInfo"))
    );
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleLogOut() {
      window.localStorage.removeItem("userInfo");
      this.$router.push("/login");
    },
    gotoUser() {
      this.$router.push("/order");
    },
  },
};
</script>
<style lang="scss" scoped>
.x-off-top {
  width: 100%;
  height: 100%;
  background: #fff;
  .x-off-top-nei {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 40px;
    .x-off-img {
      width: 172px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
    .x-off-nav {
      flex: 1;
      justify-content: center;
      display: flex;
      .el-menu-demo {
        height: 50px !important;
      }
      /deep/.el-menu--horizontal > .el-menu-item,
      .el-submenu {
        height: 50px;
        line-height: 50px;
        color: #fff;
        font-size: 14px;
      }
      /deep/.el-menu--horizontal > .el-submenu .el-submenu__title {
        height: 50px;
        line-height: 50px;
        color: #fff;
        font-size: 14px;
      }
      /deep/.el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 2px solid #F18700;
        color: #fff;
      }
      /deep/.el-menu--horizontal > .el-menu-item a {
        text-decoration: none;
      }
      /deep/.el-dropdown-menu__item,
      .el-menu-item {
        padding: 0 30px !important;
      }
      /deep/.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
        border-bottom: 2px solid #F18700;
        color: #fff;
      }
      /deep/.el-menu-item:hover{
        background: #fff !important;
      }
    }
    .x-off-info {
      width: 200px;
      height: 100%;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
      padding-right: 10px;
      .nav {
        padding: 30px;
        color: #F18700;
        font-size: 14px;
        a {
          font-weight: bold;
          color: #F18700;
          text-decoration: none;
          cursor: pointer;
        }
      }
      .top-bar__img {
        margin: 0 5px;
        padding: 2px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        box-sizing: border-box;
        border: 1px solid #eee;
        vertical-align: middle;
        cursor: pointer;
      }
      .x-off-user {
        color: #000;
      }
    }
  }
}
/deep/.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  color: #000 !important;
}
/deep/.el-menu.el-menu--horizontal{
  border: none;
}
</style>
