<!--车辆管理-->
<template>
  <el-card>
    <div class="search-form" style="text-align: left">
      <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="70px" label-position="left"
        class="demo-form-inline" size="small">
        <el-form-item label="车辆名称">
          <el-input clearable size="small" v-model="searchForm.car_name" placeholder="请输入车辆名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
          <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch">重置</el-button>
          <!-- <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="handleAdd">添加车辆</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <!-- 模拟列表 -->
    <div class="tb-box">
      <div class="tb-item" v-for="(item,index) in dataList" :key="index" @click="goToDetail(item.id)">
        <div class="tb-item-left">
          <el-image :src='item.exterior_images[0]' class="tb-item-img" fit="contain"></el-image>
        </div>
        <div class="tb-item-mid">
          <span class="tb-item-title">{{ item.car_name }}</span>
          <span class="tb-item-price">{{ item.price }}万</span>
        </div>
        <div class="tb-item-right">
          <el-button type="warning" round @click.stop="handleCancel(item.id)" size='mini' style="width: 100px;background:#F18700;margin:10px 0 0 0">删除</el-button>
        </div>
      </div>
      <el-empty description="暂无车辆信息~" v-if="dataList.length == 0"></el-empty>
    </div>
    <div class="pagization-box">
      <CustomPagization :background="true" :currentPage="current" :total="total" :pageSize="size"
        @current-change="currentChange" />
    </div>
    <!-- 表单 -->
    <!-- <AddCar ref='addForm' :record='record' @returnMes='returnMesAddForm'></AddCar> -->
  </el-card>
</template>

<script>
import { indexStoreCarList, indexStoreCarDel } from '@/api'
import AddCar from './components/addCar.vue';
export default {
  components:{
    AddCar
  },
  data() {
    return {
      record: {},
      searchForm: {
        begin_time: "",
        end_time: "",
        car_name: ""
      },
      value1:[],
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
    };
  },
  created() {
    this.getInit();
  },
  methods: {
    // 前往详情页面
    goToDetail(id){
      this.$router.push({
        path: '/detail',
        query: {
          id:id,
          name: '车辆管理'
        },
      });
    },
    // 打开详情
    handleAdd(){
      this.$refs.addForm.openDialog()
    },
    handleEdit(){
      this.$refs.addForm.openDialog()
    },
    returnMesAddForm(){
      // console.log(1111)
    },
    //删除车辆
    handleCancel(id) {
      this.$confirm("确定删除该车辆信息?", "温馨提示", {
        type: "warning",
      })
        .then(() => {
          indexStoreCarDel({ 
            store_id:window.localStorage.getItem("store_id"),
            id: id
          }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg);
              this.getInit();
            }else{
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 获取列表
    getInit(){
      indexStoreCarList({
        store_id:window.localStorage.getItem("store_id"),
        page: this.current,
        limit: this.size,
        begin_time: this.value1.length > 0 ? this.value1[0] : '',
        end_time: this.value1.length > 0 ? this.value1[1] : '',
        car_name: this.searchForm.car_name
      }).then(res => {
        if(res.code == 1){
          this.dataList = res.data.data
          this.total = res.data.total
        }
      })
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getInit();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.value1 = []
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.getInit();
    }
  },
};
</script>

<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
</style>
