import CustomPagization from '@/components/CustomPagization';
import ElementUI from 'element-ui';
import Vue from 'vue';
import App from './App.vue';
import './router/permission'; // 权限
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/element-variables.scss';

// 注册公共组件
Vue.component('CustomPagization', CustomPagization);
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render(h) { return h(App); },
}).$mount('#app');
