<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <keep-alive v-if="isKeepAlive">
      <router-view />
    </keep-alive>
    <router-view v-else/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isKeepAlive: true,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/detail") {
        this.isKeepAlive = true;
      } else {
        if (from.path === "/detail") {
          this.isKeepAlive = true;
        } else {
          this.isKeepAlive = false;
          setTimeout(() => {
            this.isKeepAlive = true;
          }, 200);
        }
      }
    },
  },
}
</script>
<style lang="scss">
@import '@/common/list.scss';
@import '@/common/fontList.scss';
@import '@/common/pagination.scss';
body{
  margin: 0 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
