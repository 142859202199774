<template>
  <div class="buy">
    <!-- 顶部搜索 -->
    <div class="buy-top">
      <div class="buy-top-left">
        <span class="buy-zhu">迈车界二手车</span><span class="buy-ci">> 我要买车</span>
      </div>
      <!-- <div class="buy-top-right">
        <el-input placeholder="请输入内容" size="mini"></el-input>
      </div> -->
    </div>
    <!-- 搜索内容 -->
    <div class="buy-con">
      <ul>
        <li v-if="!showMore">
          <span class="buy-con-name">品牌</span>
          <div class="buy-con-right buy-con-right-padding">
            <span
              :class="brandNum == index ? 'active' : ''"
              v-for="(item, index) in pinList"
              :key="index"
              @click="changeInfo(1, item.id)"
              >{{ item.name }}</span
            >
            <span class="buy-more" @click="showMore = true">更多</span>
          </div>
        </li>
        <li v-else>
          <span class="buy-con-name">品牌</span>
          <div class="buy-con-right buy-con-right-padding">
            <span
              :class="brandNum == index ? 'active' : ''"
              v-for="(item, index) in pinAllList"
              :key="index"
              @click="changeInfo(1, item.id)"
              >{{ item.name }}</span
            >
            <span class="buy-more" @click="showMore = false">收起</span>
          </div>
        </li>
        <!-- <li>
          <span class="buy-con-name">车系</span>
          <div class="buy-con-right">
            <span
              :class="seriesNum == index ? 'active' : ''"
              v-for="(item, index) in typeList"
              :key="index"
              @click="changeInfo(2, item.id)"
              >{{ item.name }}</span
            >
          </div>
        </li> -->
        <li>
          <span class="buy-con-name">价格</span>
          <div class="buy-con-right">
            <span
              :class="priceNum == index ? 'active' : ''"
              v-for="(item, index) in priceList"
              :key="index"
              @click="changeInfo(3, item.id)"
              >{{ item.name }}</span
            >
            <div class="one-search">
              <el-input placeholder="" v-model="min" size="mini" style="margin-right: 4px"></el-input>-<el-input
                placeholder=""
                size="mini"
                v-model="max"
                style="margin-left: 4px"
              ></el-input>
              <el-button type="primary" size="mini" style="margin-left: 8px" @click="changeInfo(4)">确定</el-button>
            </div>
          </div>
        </li>
        <li>
          <span class="buy-con-name">更多</span>
          <div class="buy-con-right">
            <el-select
              v-model="searchForm.discharge"
              placeholder="排量"
              size="mini"
              style="margin-left: 10px"
              class="one-select"
              @change='(v) => changeInfo(5,v)'
            >
              <el-option
                v-for="item in pailiangList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="searchForm.speed_changing_box"
              placeholder="变速"
              size="mini"
              class="one-select"
              @change='(v) => changeInfo(6,v)'
            >
              <el-option
                v-for="item in biansuList"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="searchForm.let_out"
              placeholder="排放标准"
              size="mini"
              class="one-select"
              @change='(v) => changeInfo(7,v)'
            >
              <el-option
                v-for="item in paifangList"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <el-select v-model="searchForm.yanse" placeholder="颜色" size="mini" class="one-select">
              <el-option
                v-for="item in colorList"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
          </div>
        </li>
      </ul>
      <!-- <span class="buy-car-tips">共为您找到{{ total }}辆车</span> -->
    </div>
    <!-- 车辆信息 -->
    <div class="buy-car">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="item.name"
          :name="item.id.toString()"
          v-for="(item, index) in labelList"
          :key="index"
        ></el-tab-pane>
      </el-tabs>
      <!-- 内容 -->
      <div class="home-hot-content">
        <div
          class="home-hot-item"
          v-for="(item, index) in dataList"
          :key="index"
          @click="goToDetail(item.id)"
        >
          <div class="home-hot-item-content">
            <el-image
              style="width: 334px; height: 232px"
              :src="item.exterior_images[0]"
              fit="contain"
            ></el-image>
            <div class="neis">
              <span class="neis-title">{{ item.car_name }}</span>
              <div class="neis-time">
                <span style="margin-right: 5px">{{ item.license_tag }}年</span> |
                <span style="margin-left: 5px">{{ item.show_mileage }}万公里</span>
              </div>
              <div class="neis-pice">
                <span style="font-size: 18px">{{ item.price }}</span
                >万
                <span style="margin-left: 5px">首付{{ item.down_payment_price }}万</span>
              </div>
            </div>
          </div>
        </div>

        <el-empty description="暂无车辆信息~" v-if="dataList.length == 0"></el-empty>
      </div>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { indexBannerLabel, indexIndexList } from "@/api";
export default {
  data() {
    return {
      pailiangList: [
        {
          value: "0-1.0",
          label: "0-1.0",
        },
        {
          value: "1.0-2.0",
          label: "1.0-2.0",
        },
        {
          value: "2.0-3.0",
          label: "2.0-3.0",
        },
        {
          value: "3.0-4.0",
          label: "3.0-4.0",
        },
        {
          value: "4.0-5.0",
          label: "4.0-5.0",
        },
        {
          value: "5.0-100",
          label: "5.0以上",
        },
      ],
      biansuList: [
        {
          value: "手动",
          label: "手动",
        },
        {
          value: "自动",
          label: "自动",
        },
      ],
      paifangList: [
        {
          value: "国I",
          label: "国I",
        },
        {
          value: "国II",
          label: "国II",
        },
        {
          value: "国III",
          label: "国III",
        },
        {
          value: "国IV",
          label: "国IV",
        },
        {
          value: "国V",
          label: "国V",
        },
        {
          value: "国VI",
          label: "国VI",
        },
      ],
      colorList: [
        {
          value: "黑色",
          label: "黑色",
        },
        {
          value: "白色",
          label: "白色",
        },
        {
          value: "银灰色",
          label: "银灰色",
        },
        {
          value: "深灰色",
          label: "深灰色",
        },
        {
          value: "红色",
          label: "红色",
        },
        {
          value: "橙色",
          label: "橙色",
        },
        {
          value: "绿色",
          label: "绿色",
        },
        {
          value: "蓝色",
          label: "蓝色",
        },
        {
          value: "咖啡色",
          label: "咖啡色",
        },
        {
          value: "紫色",
          label: "紫色",
        },
        {
          value: "香槟色",
          label: "香槟色",
        },
        {
          value: "多彩色",
          label: "多彩色",
        },
        {
          value: "黄色",
          label: "黄色",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      searchForm: {
        car_name: "",
        brand: "",
        car_series: "",
        price: "",
      },
      min: '',
      max: '',
      activeName: "0",
      pinList: [
        {
          id: 0,
          url: "@/assets/common/gzh.jpg",
          name: "不限",
        },
        {
          id: 1,
          url: "@/assets/common/gzh.jpg",
          name: "大众",
        },
        {
          id: 2,
          url: "@/assets/common/gzh.jpg",
          name: "本田",
        },
        {
          id: 3,
          url: "@/assets/common/gzh.jpg",
          name: "宝马",
        },
        {
          id: 4,
          url: "@/assets/common/gzh.jpg",
          name: "丰田",
        },
        {
          id: 5,
          url: "@/assets/common/gzh.jpg",
          name: "奥迪",
        },
        {
          id: 6,
          url: "@/assets/common/gzh.jpg",
          name: "奔驰",
        },
        {
          id: 7,
          url: "@/assets/common/gzh.jpg",
          name: "保时捷",
        },
        {
          id: 8,
          url: "@/assets/common/gzh.jpg",
          name: "沃尔沃",
        },
        {
          id: 9,
          url: "@/assets/common/gzh.jpg",
          name: "路虎",
        },
        {
          id: 10,
          url: "@/assets/common/gzh.jpg",
          name: "小米",
        },
        {
          id: 11,
          url: "@/assets/common/gzh.jpg",
          name: "比亚迪",
        },
        {
          id: 12,
          url: "@/assets/common/gzh.jpg",
          name: "长城",
        },
        {
          id: 13,
          url: "@/assets/common/gzh.jpg",
          name: "长安",
        },
        {
          id: 14,
          url: "@/assets/common/gzh.jpg",
          name: "本田",
        },
        {
          id: 15,
          url: "@/assets/common/gzh.jpg",
          name: "别克",
        },
        {
          id: 16,
          url: "@/assets/common/gzh.jpg",
          name: "福特",
        },
        {
          id: 17,
          url: "@/assets/common/gzh.jpg",
          name: "特斯拉",
        },
        {
          id: 18,
          url: "@/assets/common/gzh.jpg",
          name: "哈佛",
        },
        {
          id: 19,
          url: "@/assets/common/gzh.jpg",
          name: "悍马",
        },
        {
          id: 20,
          url: "@/assets/common/gzh.jpg",
          name: "吉利",
        },
      ],
      pinAllList: [
        {
          id: 0,
          url: "@/assets/common/gzh.jpg",
          name: "不限",
        },
        {
          id: 1,
          url: "@/assets/common/gzh.jpg",
          name: "大众",
        },
        {
          id: 2,
          url: "@/assets/common/gzh.jpg",
          name: "本田",
        },
        {
          id: 3,
          url: "@/assets/common/gzh.jpg",
          name: "宝马",
        },
        {
          id: 4,
          url: "@/assets/common/gzh.jpg",
          name: "丰田",
        },
        {
          id: 5,
          url: "@/assets/common/gzh.jpg",
          name: "奥迪",
        },
        {
          id: 6,
          url: "@/assets/common/gzh.jpg",
          name: "奔驰",
        },
        {
          id: 7,
          url: "@/assets/common/gzh.jpg",
          name: "保时捷",
        },
        {
          id: 8,
          url: "@/assets/common/gzh.jpg",
          name: "沃尔沃",
        },
        {
          id: 9,
          url: "@/assets/common/gzh.jpg",
          name: "路虎",
        },
        {
          id: 10,
          url: "@/assets/common/gzh.jpg",
          name: "小米",
        },
        {
          id: 11,
          url: "@/assets/common/gzh.jpg",
          name: "比亚迪",
        },
        {
          id: 12,
          url: "@/assets/common/gzh.jpg",
          name: "长城",
        },
        {
          id: 13,
          url: "@/assets/common/gzh.jpg",
          name: "长安",
        },
        {
          id: 14,
          url: "@/assets/common/gzh.jpg",
          name: "本田",
        },
        {
          id: 15,
          url: "@/assets/common/gzh.jpg",
          name: "别克",
        },
        {
          id: 16,
          url: "@/assets/common/gzh.jpg",
          name: "福特",
        },
        {
          id: 17,
          url: "@/assets/common/gzh.jpg",
          name: "特斯拉",
        },
        {
          id: 18,
          url: "@/assets/common/gzh.jpg",
          name: "哈佛",
        },
        {
          id: 19,
          url: "@/assets/common/gzh.jpg",
          name: "悍马",
        },
        {
          id: 20,
          url: "@/assets/common/gzh.jpg",
          name: "吉利",
        },
        {
          id: 21,
          url: "@/assets/common/gzh.jpg",
          name: "凯迪拉克",
        },
        {
          id: 22,
          url: "@/assets/common/gzh.jpg",
          name: "理想",
        },
        {
          id: 23,
          url: "@/assets/common/gzh.jpg",
          name: "林肯",
        },
        {
          id: 24,
          url: "@/assets/common/gzh.jpg",
          name: "马自达",
        },
        {
          id: 25,
          url: "@/assets/common/gzh.jpg",
          name: "奇瑞",
        },
        {
          id: 26,
          url: "@/assets/common/gzh.jpg",
          name: "蔚蓝",
        },        
        {
          id: 27,
          url: "@/assets/common/gzh.jpg",
          name: "现代",
        },
      ],
      typeList: [
        {
          id: 0,
          url: "@/assets/common/gzh.jpg",
          name: "不限",
        },
        {
          id: 1,
          url: "@/assets/common/gzh.jpg",
          name: "奔驰C级",
        },
        {
          id: 2,
          url: "@/assets/common/gzh.jpg",
          name: "奔驰E级",
        },
        {
          id: 3,
          url: "@/assets/common/gzh.jpg",
          name: "宝马3系",
        },
        {
          id: 4,
          url: "@/assets/common/gzh.jpg",
          name: "宝马5系",
        },
        {
          id: 5,
          url: "@/assets/common/gzh.jpg",
          name: "宝马X1",
        },
        {
          id: 6,
          url: "@/assets/common/gzh.jpg",
          name: "奥迪A4L",
        },
        {
          id: 7,
          url: "@/assets/common/gzh.jpg",
          name: "奥迪A6L",
        },
        {
          id: 8,
          url: "@/assets/common/gzh.jpg",
          name: "高尔夫",
        },
        {
          id: 9,
          url: "@/assets/common/gzh.jpg",
          name: "迈腾",
        },
        {
          id: 10,
          url: "@/assets/common/gzh.jpg",
          name: "速腾",
        },
        {
          id: 11,
          url: "@/assets/common/gzh.jpg",
          name: "朗逸",
        },
      ],
      priceList: [
        {
          id: 0,
          name: "不限",
          min: "",
          max: "",
        },
        {
          id: 1,
          name: "3万以下",
          min: 0,
          max: 3,
        },
        {
          id: 2,
          name: "3-5万",
          min: 3,
          max: 5,
        },
        {
          id: 3,
          name: "5-8万",
          min: 5,
          max: 8,
        },
        {
          id: 4,
          name: "8-10万",
          min: 8,
          max: 10,
        },
        {
          id: 5,
          name: "10-15万",
          min: 10,
          max: 15,
        },
        {
          id: 6,
          name: "15-20万",
          min: 15,
          max: 20,
        },
        {
          id: 7,
          name: "20-30万",
          min: 20,
          max: 30,
        },
        {
          id: 8,
          name: "30-50万",
          min: 30,
          max: 50,
        },
        {
          id: 9,
          name: "50-100万",
          min: 50,
          max: 100,
        },
        {
          id: 10,
          name: "100万+",
          min: 100,
          max: 1000,
        },
      ],
      labelList: [
        {
          id: 0,
          name: "全部",
        },
      ],
      labelNum: 0,
      brandNum: 0,
      seriesNum: 0,
      priceNum: 0,
      current: 1,
      size: 12,
      total: 0,
      dataList: [],
      showMore: false
    };
  },
  created() {
    this.getIndexBannerLabel();
    if (this.$route.query.car_name !== undefined) {
      this.searchForm.car_name = this.$route.query.car_name;
    }
    if (this.$route.query.brand !== undefined) {
      this.searchForm.brand = this.pinAllList[this.$route.query.brand].name;
      this.brandNum = this.$route.query.brand;
      if( this.$route.query.brand > 20){
        this.showMore = true
      }else {
        this.showMore = false
      }
    }
    if (this.$route.query.price !== undefined) {
      this.searchForm.price =
        this.priceList[this.$route.query.price].min +
        "-" +
        this.priceList[this.$route.query.price].max;
      this.priceNum = this.$route.query.price;
      this.min = ''
      this.max = ''
    }
    this.getIndexCarList();
  },
  methods: {
    handleClick(tab, event) {
      this.labelNum = tab.index;
      this.current = 1;
      this.getIndexCarList();
    },
    changeInfo(id, num) {
      if (id == 1) {
        this.brandNum = num;
        this.searchForm.brand = num == 0 ? "" : this.pinAllList[num].name;
        this.current = 1;
        this.getIndexCarList();
      } else if (id == 2) {
        this.searchForm.car_series = num == 0 ? "" : this.typeList[num].name;
        this.seriesNum = num;
        this.current = 1;
        this.getIndexCarList();
      } else if (id == 3) {
        this.current = 1;
        this.searchForm.price =
          num == 0 ? "" : (this.priceList[num].min + "-" + this.priceList[num].max);
        this.priceNum = num;
        this.min = ''
        this.max = ''
        this.getIndexCarList();
      }else if(id == 4){
        this.current = 1;
        this.priceNum = 1000;
        this.searchForm.price = ((this.min ? this.min : 0) + (this.max ? ('-' + this.max) : 1000))
        this.getIndexCarList();
      }else if(id == 5){
        this.current = 1;
        this.searchForm.discharge = num
        this.getIndexCarList()
      }else if(id == 6){
        this.current = 1;
        this.searchForm.speed_changing_box = num
        this.getIndexCarList()
      }else if(id == 7){
        this.current = 1;
        this.searchForm.let_out = num
        this.getIndexCarList()
      }
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getIndexCarList();
    },
    // 获取首页导航
    getIndexBannerLabel() {
      indexBannerLabel().then((res) => {
        if (res.code == 1) {
          this.labelList = [
            {
              id: 0,
              name: "全部",
            },
          ];
          this.labelList = this.labelList.concat(res.label);
        }
      });
    },
    // 获取首页车辆数据
    getIndexCarList() {
      indexIndexList({
        u_id: window.localStorage.getItem("userInfo")
          ? JSON.parse(window.localStorage.getItem("userInfo")).id
          : 0,
        page: this.current,
        limit: this.size,
        label_id: this.labelNum,
        brand: this.searchForm.brand,
        car_series: this.searchForm.car_series,
        price: this.searchForm.price,
        car_name: this.searchForm.car_name,
        discharge: this.searchForm.discharge,
        speed_changing_box: this.searchForm.speed_changing_box,
        let_out: this.searchForm.let_out
      }).then((res) => {
        if (res.code == 1) {
          this.dataList = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    // 前往详情页面
    goToDetail(id) {
      this.$router.push({
        path: "/detail",
        query: {
          id: id,
          name: '我要买车'
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.buy {
  width: 1448px;
  margin: 0 auto;
  .buy-top {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    .buy-top-left {
      .buy-zhu {
        font-size: 16px;
        font-weight: 600;
        font-family: Source Han Sans CN;
      }
      .buy-ci {
        font-size: 14px;
        margin-left: 5px;
        color: #666;
        font-family: Source Han Sans CN;
      }
    }
  }
  .buy-con {
    width: 100%;
    ul {
      display: block;
      width: 100%;
      border: 1px solid #dadde5;
      list-style: none;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      li {
        height: 50px;
        border-bottom: 1px solid #dadde5;
        display: flex;
        justify-content: space-between;
        &:last-child {
          border: none;
        }
        .buy-con-name {
          display: block;
          width: 100px;
          height: 100%;
          background: #f3f2f2;
          text-align: center;
          line-height: 50px;
          font-size: 14px;
          flex-shrink: 0;
        }
        .buy-con-right {
          flex: 1;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          span {
            font-size: 14px;
            margin: 0 10px;
            padding: 0px 5px;
            flex-shrink: 0;
            cursor: pointer;
          }
          .active {
            background: #f18700;
            color: #fff;
          }
          .one-search {
            width: 240px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .one-select {
            width: 100px;
            margin-right: 10px;
          }
        }
        .buy-con-right-padding{
          box-sizing: border-box;
          padding-right: 50px;
          position: relative;
          .buy-more{
            position: absolute;
            right: 0;
            color: #f18700;
          }
        }
      }
    }
    .buy-car-tips {
      display: block;
      width: 100%;
      text-align: left;
      font-size: 12px;
      color: #333;
      line-height: 30px;
    }
  }
  .buy-car {
    width: 100%;
    /deep/.el-tabs__nav-wrap::after {
      height: 0 !important;
    }
  }
}
</style>
