import { get, post } from '@/utils/request';

// 请求接口
export const usersSendSms = (param) => get('users/send_sms',param); // 手机号登录发送验证码
export const usersSmsLogin = (param) => get('users/sms_login',param); // 短信验证码登录
export const indexBannerLabel = (param) => get('index/bannerLabel',param); // 首页banner和标签
export const indexIndexList = (param) => get('index/index',param); // 首页车辆数据
export const indexCarDetail = (param) => get('index/carDetail',param); // 车辆详情
export const indexCollect = (param) => get('index/collect',param); // 收藏和取消收藏车辆
export const indexCollectList = (param) => get('index/collectList',param); // 收藏列表
export const indexTrackList = (param) => get('index/trackList',param); // 足迹列表
export const indexTrackDel = (param) => get('index/trackDel',param); // 删除足迹
export const indexUserIndex = (param) => get('index/userIndex',param); // 个人中心
export const indexIsStore = (param) => get('index/isStore',param); // 用户是否为商家
export const indexStoreEnter = (data) => post('index/storeEnter',data); // 商家入驻
export const commonUpload = (data) => post('common/upload',data); // 文件上传
export const indexStoreEnterSms = (param) => get('index/storeEnterSms',param); // 商家入驻发送验证码
export const indexStoreData = (param) => get('index/storeData',param); // 商家概况
export const indexStoreCarList = (param) => get('index/storeCarList',param); // 商家车辆管理
export const indexStoreCarDel = (param) => get('index/storeCarDel',param); // 商家车辆删除
export const indexUserSubscribe = (param) => get('index/userSubscribe',param); // 用户预约看车
export const indexStoreSubscribeList = (param) => get('index/storeSubscribeList',param); // 商家收到的预约看车列表
export const indexSubscribeDel = (param) => get('index/subscribeDel',param); // 商家删除预约看车申请
export const indexBargain = (param) => get('index/bargain',param); // 用户砍价
export const indexUserBargainList = (param) => get('index/userBargainList',param); // 用户砍价列表
export const indexUserBargainDel = (param) => get('index/userBargainDel',param); // 用户删除砍价
export const indexStoreBargainList = (param) => get('index/storeBargainList',param); // 商家收到的砍价列表
export const indexStoreBargainStatus = (param) => get('index/storeBargainStatus',param); // 商家收到的砍价同意或拒绝
export const indexOrder = (data) => post('index/order',data); // 下单
export const indexUserOrder = (param) => get('index/userOrder',param); // 用户订单
export const indexStoreOrder = (param) => get('index/storeOrder',param); // 商家订单
export const indexSellCar = (data) => post('index/sellCar',data); // 我要卖车
export const wxpay = (param) => get('index/native_wxpay',param); // 支付
export const payzt = (param) => get('/index/pay_zt',param); // 支付状态
export const recommend = (param) => get('index/recommend',param); // 更多二手车
export const clauseInfo = (param) => get('/index/content',param); // 关于我们二手车交易规则