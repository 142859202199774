<template>
  <el-card class="box-card">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="`全部(${count.all_count})`" name="4">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==4' />
      </el-tab-pane>
      <el-tab-pane :label="`待支付(${count.aunpaid_count})`" name="0">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==0' />
      </el-tab-pane>
      <el-tab-pane :label="`待提车(${count.stay_count})`" name="1">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==1' />
      </el-tab-pane>
      <el-tab-pane :label="`已完成(${count.complete_count})`" name="2">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==2' />
      </el-tab-pane>
      <el-tab-pane :label="`已取消(${count.cancel_count})`" name="3">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==3' />
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
  import OrderList from './components/orderList.vue';
  export default {
    components: {
      OrderList
    },
    data() {
      return {
        activeName: '4',
        count: {
          all_count: 0, //全部
          aunpaid_count: 0, //待支付
          stay_count: 0, //待提车
          complete_count: 0, //已完成
          cancel_count: 0 //已取消
        },
      };
    },
    created(){
      if (this.$route.query.id !== undefined) {
        this.activeName = this.$route.query.id;
      }
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab, event);
      },
      changelist(count,num){
        this.count = count
        if(num){
          this.activeName = num
        }
      }
    }
  };
</script>