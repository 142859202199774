<template>
  <div class="">
    <el-dialog
      title="订单详情"
      :visible.sync="visible"
      width="850px"
      append-to-body
      v-if="Object.keys(detail).length"
      :before-close="handleClose"
    >
      <!-- 顶部 -->
      <div class="detail-top">
        <div class="detail-top-img">
          <el-image :src="detail.car.exterior_images[0]" class="detail-item-img"></el-image>
        </div>
        <div class="detail-top-box">
          <span class="detail-title">{{ detail.car.car_name }}</span>
          <span class="detail-price">{{ detail.car_money / 10000 }}万</span>
          <div class="detail-info">
            <div class="detail-half detail-info-info">过户人信息</div>
            <div class="detail-half detail-info-info">交付信息</div>
            <div class="detail-half">姓名：{{ detail.username }}</div>
            <div class="detail-half">上牌城市：{{ detail.city }}</div>
            <div class="detail-half">证件号：{{ detail.card_num }}</div>
            <div class="detail-half">物流方式：{{ detail.logistics_type }}</div>
          </div>
        </div>
      </div>
      <!-- 中部 -->
      <div class="detail-mid">
        <div class="detail-mid-nei">
          <div class="detail-mid-item">
            <div class="detail-mid-name">商品</div>
            <div class="detail-mid-info">
              车款： <span>{{ detail.car_money }}元</span>
            </div>
          </div>
          <div class="detail-mid-item">
            <div class="detail-mid-name">物流</div>
            <div class="detail-mid-info">物流费： <span>1000元</span></div>
          </div>
          <div class="detail-mid-item">
            <div class="detail-mid-name">其他</div>
            <div class="detail-mid-info">过户费：<span>1000元</span></div>
          </div>
          <!-- <div class="detail-mid-item">
            <div class="detail-mid-name"></div>
            <div class="detail-mid-info">交车保证金（可退）：<span>1,000元</span></div>
          </div> -->
        </div>
        <div class="detail-mid-price">
          <span>购车总价：</span> <span class="detail-p">{{ detail.total_price }}元</span>
        </div>
      </div>
      <!-- 底部 -->
      <div class="detail-bottom">
        <div class="detail-bottom-item">
          <span class="detail-ps">{{
            detail.pay_zt == 0
              ? "待支付"
              : detail.pay_zt == 1
              ? "待提车"
              : detail.pay_zt == 2
              ? "已完成"
              : "已取消"
          }}</span>
          <div class="detail-infos">
            订金：<span class="detail-ps">{{ detail.down_paymen_money }}元</span>
          </div>
        </div>
        <div class="detail-bottom-item">
          <span class="detail-ps" style="color: #000">确认收车前支付</span>
          <div class="detail-infos">
            尾款：<span>{{ detail.end_money }}元</span>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <span>订单编号：{{ detail.order_num }}</span>
        <el-button
          v-if='detail.pay_zt == 0 && listType == 1'
          type="warning"
          round
          @click="submit"
          size="small"
          style="width: 120px; background: #f18700"
          >去支付</el-button
        >
      </span>
    </el-dialog>
    <!-- 支付 -->
    <el-dialog
      title="请使用微信扫码支付"
      :visible.sync="payVisible"
      width="300px"
      append-to-body
      :before-close="handleClosePay"
    >
       <Pay ref='payMethod' :url='url' v-if="payVisible" :order_num='detail.order_num' :type='1'></Pay>
    </el-dialog>
  </div>
</template>

<script>
import { wxpay } from '@/api'
import Pay from './pay.vue';
export default {
  props: {
    detail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    listType: {
      type: Number,
      default: 1, // 1为用户  2为商家
    },
  },
  components:{
    Pay
  },
  data() {
    return {
      visible: false,
      payVisible: false,
      url: ''
    };
  },
  methods: {
    // 打开弹窗
    openDialog() {
      this.visible = true;
    },
    // 点击知道了
    handleClose() {
      this.visible = false;
      this.$emit("returnMes");
    },
    //关闭支付
    handleClosePay(){
      this.payVisible = false
      this.url = ''
    },
    submit() {
      let data = {
        order_num: this.detail.order_num,
        u_id: JSON.parse(window.localStorage.getItem("userInfo")).id
      }
      wxpay(data).then((res) => {
        if(res.code == 1){
          this.url = res.data
          this.payVisible = true
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 0 20px;
}
.detail-top {
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .detail-top-img {
    width: 240px;
    height: 160px;
    border-radius: 4px;
    flex-shrink: 0;
    margin-right: 30px;
    .detail-item-img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  .detail-top-box {
    flex: 1;
    height: 100%;
    .detail-title {
      font-size: 18px;
      line-height: 32px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; /* 显示省略号 */
      display: block;
    }
    .detail-price {
      font-size: 16px;
      line-height: 32px;
      color: #f18700;
      display: block;
    }
    .detail-info {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .detail-info-info {
        font-weight: bold;
        line-height: 32px !important;
        margin-top: 5px;
      }
      .detail-half {
        font-size: 14px;
        line-height: 28px;
        width: 50%;
      }
    }
  }
}
.detail-mid {
  width: 100%;
  height: 226px;
  margin: 30px 0;
  background: #f0f2f5;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px 20px 0;
  .detail-mid-nei {
    width: 100%;
    height: 140px;
    border-bottom: 2px solid #dee2e7;
    .detail-mid-item {
      display: flex;
      height: 35px;
      width: 100%;
      align-items: center;
      .detail-mid-name {
        font-size: 14px;
        font-weight: bold;
        width: 30%;
        .detail-mid-info {
          font-size: 14px;
          color: #555;
          span {
            color: #000;
          }
        }
      }
    }
  }
  .detail-mid-price {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .detail-p {
      font-size: 16px;
      color: #f18700;
    }
  }
}
.detail-bottom {
  width: 100%;
  height: 102px;
  border-bottom: 2px solid #dee2e7;
  box-sizing: border-box;
  padding: 0 20px;
  .detail-bottom-item {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .detail-ps {
      font-size: 16px;
      color: #f18700;
    }
    .detail-infos {
      font-size: 14px;
    }
  }
}
.dialog-footer {
  width: 100%;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 14px;
    color: #333;
  }
}
</style>
