<template>
  <div class="">
    <img
      :src="require('@/assets/sell/banner.png')"
      alt="Carousel Image"
      style="display: inline-block; width: 100%"
    />
    <!-- 卖车信息 -->
    <div class="sell">
      <div class="sell-input">
        <el-input placeholder="请输入手机号码" v-model="keyword">
          <template slot="append">
            <div @click="handleEstimate" class="input-btn">快速估价</div>
          </template>
        </el-input>
      </div>
      <div class="sell-box">
        <span class="sell-title">迈车界车辆估价服务</span>
        <div class="sell-mes">
          <div class="sell-item">
            <div class="sell-item-img">
              <el-image
                style="width: 140px; height: 140px"
                :src="require('@/assets/sell/sell1.png')"
                fit="contain"
              ></el-image>
            </div>
            <span>快速评估</span>
          </div>
          <div class="sell-item">
            <div class="sell-item-img">
              <el-image
                style="width: 140px; height: 140px"
                :src="require('@/assets/sell/sell2.png')"
                fit="contain"
              ></el-image>
            </div>
            <span>省时省力</span>
          </div>
          <div class="sell-item">
            <div class="sell-item-img">
              <el-image
                style="width: 140px; height: 140px"
                :src="require('@/assets/sell/sell3.png')"
                fit="contain"
              ></el-image>
            </div>
            <span>优质客服</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 表单弹窗 -->
    <el-dialog
      title="快速估价"
      :visible.sync="sellVisible"
      width="600px"
      :before-close="handleSellClose"
      append-to-body
      size='small'
    >
      <SellForm @close='close'></SellForm>
    </el-dialog>
  </div>
</template>

<script>
import SellForm from './components/sellForm.vue';
export default {
  components: {
    SellForm,
  },
  data() {
    return {
      keyword:'',
      sellVisible: false
    };
  },
  methods: {
    handleEstimate(){
      this.sellVisible = true
    },
    handleSellClose(){
      this.sellVisible = false
    },
    close(){
      this.sellVisible = false
    }
  },
};
</script>
<style lang="scss" scoped>
.sell {
  width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 80px 0;
  .sell-input {
    width: 788px;
    height: 40px;
    margin: 0 auto 60px;
    display: flex;
    align-items: center;
    .input-btn{
      display: block;
      width: 97px;
      height: 38px;
      border-radius: 0 8px 8px 0;
      line-height: 38px;
      cursor: pointer;
    }
    /deep/.el-input-group {
      border: 1px solid #f18700;
      border-radius: 8px;
    }
    /deep/.el-input__inner {
      background: #f5f5f5;
      border-radius: 8px 0 0 8px;
    }
    /deep/.el-input-group__append {
      border-radius: 0 8px 8px 0;
      background: #f18700;
      color: #fff;
      padding: 0 !important;
    }
  }
  .sell-box {
    width: 1080px;
    height: 390px;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    .sell-title {
      display: block;
      width: 100%;
      height: 112px;
      text-align: center;
      font-family: Source Han Sans CN;
      font-weight: 500;
      font-size: 32px;
      color: #f18700;
      box-sizing: border-box;
      padding: 40px 0 40px;
    }
    .sell-mes {
      width: 100%;
      height: 250px;
      display: flex;
      .sell-item {
        flex: 1;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .sell-item-img{
          width: 100%;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span {
          font-family: Source Han Sans CN;
          font-size: 20px;
          color: #3d3d3d;
          margin-top: 26px;
        }
      }
    }
  }
}
</style>
