<template>
  <div class="">
    <img
      :src="require('@/assets/host/host.png')"
      alt="Carousel Image"
      style="display: inline-block; width: 100%"
    />
    <!-- 主体部分 -->
    <div class="host">
      <div class="host-title">商家入驻申请</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item label="商家名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入商家名称"></el-input>
        </el-form-item>
        <el-form-item label="联系人姓名" prop="username">
          <el-input v-model="ruleForm.username" placeholder="请输入联系人姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="sms_code" class="code-input">
          <el-input
            v-model="ruleForm.sms_code"
            autocomplete="off"
            clearable
            placeholder="请输入验证码"
            style="width: 390px"
          ></el-input>
          <div class="code" v-if="codeCd">获取验证码({{ long }})</div>
          <div class="code" v-else @click="sendPhoneCode('ruleForm')">获取验证码</div>
        </el-form-item>
        <el-form-item label="营业执照" prop="yyzz_image">
          <el-upload
            class="avatar-uploader"
            :limit="1"
            action="https://mcjzz.com/api/common/upload"
            :on-success="uploadStripping"
            :on-error="UploadError"
          >
            <img v-if="ruleForm.yyzz_image" :src="ruleForm.yyzz_image" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="身份证照片" prop="card_image">
          <el-upload
            class="avatar-uploader"
            :limit="1"
            action="https://mcjzz.com/api/common/upload"
            :on-success="uploadStripping1"
            :on-error="UploadError1"
          >
            <img v-if="ruleForm.card_image" :src="ruleForm.card_image" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item prop="type" style="text-align: left">
          <el-checkbox v-model="ruleForm.type"></el-checkbox>
          <span style="padding-left: 10px; color: #f18700"
            >同意<span
              style="color: #f18700; text-decoration: none; cursor: pointer"
              @click="formVisible = true"
              >《服务协议额》</span
            ></span
          >
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button size="small" type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <el-button size="small" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { indexStoreEnter, indexStoreEnterSms } from "@/api";
export default {
  data() {
    return {
      long: 60,
      codeCd: false,
      visible: false,
      ruleForm: {
        is_default: 0,
        yyzz_image: "",
        card_image: "",
      },
      rules: {
        name: [{ required: true, message: "请输入商家名称", trigger: "blur" }],
        username: [{ required: true, message: "请输入联系人姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" },{ pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }],
        sms_code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        yyzz_image: [{ required: true, message: "请选择营业执照", trigger: "blur" }],
        card_image: [{ required: true, message: "请选择身份证照片", trigger: "blur" }],
        type: [{ required: true, message: "请先阅读同意《服务协议额》", trigger: "change" }],
      },
    };
  },
  methods: {
    // 发送验证码
    sendPhoneCode(formName) {
      this.$refs[formName].validateField("phone", async (valid) => {
        if (!valid) {
          // 获取验证码
          this.getIndexStoreEnterSms();
          // 开启计时
          this.codeCd = true;
          const timer = setInterval(() => {
            this.long--;
            if (this.long <= 0) {
              this.long = 60;
              this.codeCd = false;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          return false;
        }
      });
    },
    // 获取验证码
    getIndexStoreEnterSms() {
      indexStoreEnterSms({
        u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
        phone: this.ruleForm.phone,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    uploadStripping(res, file, fileList) {
      if (res.code == 1) {
        this.$message.success(res.msg);
        this.ruleForm.yyzz_imagelist = [];
        this.ruleForm.yyzz_imagelist.push(res.data.fullurl);
        this.ruleForm.yyzz_image = res.data.fullurl;
      } else {
        this.$message.error(res.msg);
      }
    },
    UploadError(err, file, fileList) {
      let myError = err.toString();
      myError = myError.replace("Error: ", "");
      myError = JSON.parse(myError);
      this.$message.error(myError.msg);
    },
    uploadStripping1(res, file, fileList) {
      if (res.code == 1) {
        this.$message.success(res.msg);
        this.ruleForm.card_imagelist = [];
        this.ruleForm.card_imagelist.push(res.data.fullurl);
        this.ruleForm.card_image = res.data.fullurl;
      } else {
        this.$message.error(res.msg);
      }
    },
    UploadError1(err, file, fileList) {
      let myError = err.toString();
      myError = myError.replace("Error: ", "");
      myError = JSON.parse(myError);
      this.$message.error(myError.msg);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.ruleForm;
          indexStoreEnter({
            u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
            ...form,
          }).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.$refs[formName].resetFields();
              this.ruleForm.type = false
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm.type = false
    },
  },
};
</script>
<style lang="scss" scoped>
.host {
  width: 1440px;
  .host-title {
    width: 100%;
    height: 80px;
    font-size: 20px;
    text-align: center;
    line-height: 80px;
  }
  .demo-ruleForm {
    width: 600px;
    margin: 0 auto;
    .code-input {
      .code {
        font-size: 14px;
        cursor: pointer;
        color: #fff;
        margin-left: 10px;
        flex: 1;
        text-align: center;
        width: 100px;
        height: 32px;
        background: #f18700;
        float: right;
        border-radius: 4px;
      }
    }
    .avatar-uploader {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 130px;
      height: 130px;
    }
    .avatar-uploader:hover {
      border-color: #f18700;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 130px;
      height: 130px;
      line-height: 130px;
      text-align: center;
    }
    .avatar {
      width: 130px;
      height: 130px;
      display: block;
    }
  }
}
</style>
