<template>
  <el-container>
    <el-aside :style="{width: isCollapse ? '60px': '230px'}" style="background-color: rgb(238, 241, 246)">
      <Side ref="sideChild"></Side>
    </el-aside>
    <el-container class="x-contets">
      <el-header style="text-align: right; font-size: 12px">
        <Top :triggerMethod="triggerSideMethod"></Top>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
    <!-- 提示 -->
    <!-- <div class="tips">
      <Tip></Tip>
    </div> -->
  </el-container>
</template>

<script>
import Top from './top/top'
import Side from './sidebar/side'
import Tip from '@/components/tip'
import { indexIsStore } from '@/api'
export default {
  components: {
    Top,
    Side,
    Tip
  },
  data() {
    return {
      isCollapse: false,
    };
  },
  created(){
    this.getindexIsStore()
  },
  methods: {
    triggerSideMethod(e) {
      this.isCollapse = e
      const childComponent = this.$refs.sideChild;
      childComponent.receiveData(e);
    },
    // 判断是否是商家
    getindexIsStore() {
      indexIsStore({
        u_id:JSON.parse(window.localStorage.getItem("userInfo")).id,
      }).then(res => {
        if (res.code == 1 && res.msg == '是商家') {
          window.localStorage.setItem("store_id",res.store_id)
        }else {
          window.localStorage.removeItem("store_id")
        }
      })
    },
  }
};
</script>
<style lang='scss' scoped>
.el-header {
  line-height: 60px;
  height: 50px !important;
  padding: 0 !important;
}
.x-contets{
  height: 100vh;
}
.el-aside {
  color: #333;
}
.el-main{
  background-color: #F0F2F5;
  box-sizing: border-box;
  padding: 10px !important; 
  overflow: hidden;
  // overflow: auto;
}
  .tips{
    position: fixed;
    right: 0px;
    bottom: 140px;
  }
</style>