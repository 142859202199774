<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="small"
    >
      <el-form-item label="价格" prop="money">
        <el-input v-model="ruleForm.money" placeholder="请输入价格">
           <template slot="append">万</template>
        </el-input>
      </el-form-item>

      <el-form-item style="text-align: right">
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button size="small" @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { indexBargain } from "@/api";
export default {
  props: {
    id: {
      type: [Number, String],
      default: '',
    },
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        money: [{ required: true, message: "请输入价格", trigger: "blur" }]
      },
      id: "",
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.ruleForm;
          indexBargain({
            id: this.id,
            u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
            ...form,
          }).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.$emit("closeBargain");
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 10px 20px;
}
</style>
