<template>
  <div class="">
    <img
      :src="require('@/assets/host/host.png')"
      alt="Carousel Image"
      style="display: inline-block; width: 100%"
    />
    <!-- 主体部分 -->
    <div class="host">
      <div class="host-title">联系我们</div>
      <span class="host-com" style="margin-top: 20px">河南时之迈信息科技有限公司</span>
      <span class="host-com">公司地址：河南省郑州市金水区林科路非常国际6号院4号楼1706</span>
      <span class="host-com">咨询电话：4000881959</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.host {
  width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: 20px;
  .host-title {
    width: 100%;
    height: 80px;
    font-size: 20px;
    text-align: center;
    line-height: 80px;
    border-bottom: 1px solid #ccc;
  }
  .host-com{
    display: block;
    font-size: 14px;
    line-height: 32px;
    text-align: left;
  }
}
</style>
