<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="small"
    >
      <el-form-item label="车辆品牌" prop="pinpai">
        <el-select v-model="ruleForm.pinpai" placeholder="请选择车辆品牌" style="width: 100%">
          <el-option
            v-for="(item, index) in typeList"
            :key="index"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车辆系列" prop="chexi">
        <el-input v-model="ruleForm.chexi" placeholder="请输入车辆系列"></el-input>
      </el-form-item>
      <el-form-item label="车辆款型" prop="kuanxing">
        <el-input v-model="ruleForm.kuanxing" placeholder="请输入车辆款型"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
      </el-form-item>

      <el-form-item style="text-align: right">
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button size="small" @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { indexSellCar } from '@/api'
export default {
  data() {
    return {
      visible: false,
      typeList: [
        {
          url: "@/assets/common/gzh.jpg",
          name: "大众",
        },
        {
          url: "@/assets/common/gzh.jpg",
          name: "本田",
        },
        {
          url: "@/assets/common/gzh.jpg",
          name: "宝马",
        },
        {
          url: "@/assets/common/gzh.jpg",
          name: "丰田",
        },
        {
          url: "@/assets/common/gzh.jpg",
          name: "奥迪",
        },
        {
          url: "@/assets/common/gzh.jpg",
          name: "奔驰",
        },
        {
          url: "@/assets/common/gzh.jpg",
          name: "保时捷",
        },
        {
          url: "@/assets/common/gzh.jpg",
          name: "沃尔沃",
        },
        {
          url: "@/assets/common/gzh.jpg",
          name: "路虎",
        },
      ],
      ruleForm: {},
      rules: {
        pinpai: [{ required: true, message: "请输入车辆品牌", trigger: "change" }],
        chexi: [{ required: true, message: "请输入车辆系列", trigger: "blur" }],
        kuanxing: [{ required: true, message: "请输入车辆款型", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.ruleForm;
          indexSellCar({
            u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
            ...form,
          }).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.$refs[formName].resetFields();
              this.$emit("close");
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 10px 20px;
}
</style>
