<template>
  <div class="logo-img">
    <img :src="url" class="pay-img" />
  </div>
</template>

<script>
import { payzt } from "@/api";
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    order_num: {
      type: [Number, String],
      default: "",
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  mounted() {
    // 定时器
    this.timer = setInterval(() => {
      this.getPayzt();
    }, 1000);
  },
  methods: {
    getPayzt() {
      payzt({
        order_num: this.order_num,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          if (this.type == 1) {
            this.$router.replace({
              path: "/order",
              query: {
                id: 1,
              },
            }); // 这个跳转具体页面时再用
            this.$router.go(0);
          } else if (this.type == 2) {
            this.$router.replace({
              path: "/order",
              query: {
                id: 1,
              },
            });
          }
        }
      });
    },
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style lang="scss" scoped>
.logo-img {
  width: 100%;
  height: 100%;
  .pay-img {
    width: 260px;
    height: 260px;
    margin: 20px auto;
  }
}
</style>
