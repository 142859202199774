import NProgress from 'nprogress';
import router from './index';
import 'nprogress/nprogress.css';
import ElementUI, { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue';

Vue.use(ElementUI);

NProgress.configure({ showSpinner: false });
const whiteList = [ // 无需token
  '/home',
  '',
  '/',
  '/buy',
  '/sell',

  '/about',
  '/login',
  '/register',
  '/detail',
  '/contactUs'
];
// '/host',
router.beforeEach((to, from, next) => {
  NProgress.start();
  const hasToken = JSON.parse(window.localStorage.getItem("userInfo"));
  const isWhiteListed = whiteList.indexOf(to.path) !== -1;

  try {
    if (hasToken) {
      if (to.path === '/login' || to.path === '/register') {
        next({ path: '/' });
        document.title = '迈车界二手车';
      } else if (to.meta.iscompany === 2 && !window.localStorage.getItem("store_id")) {
        Message.error('您还没不是商家，请先入驻商家!');
        next(from.path); // 用户不是商家时，可以选择跳转到其他页面
      } else {
        document.title = '迈车界二手车-' + to.meta.title
        next(); // 确保 next() 被调用
      }
    } else {
      if (isWhiteListed) {
        if(to.path == '/'){
          document.title = to.meta.title
        }else{
          document.title = '迈车界二手车-' + to.meta.title
        }
        next();
      } else {
        document.title = '登录'
        next({ path: '/login' });
      }
    }
  } catch (error) {
    console.error('路由守卫中发生错误:', error);
    // 可以选择重定向到一个错误处理页面或显示一个错误消息
    next('/error');
  }
});

router.afterEach(() => {
  NProgress.done();
});
