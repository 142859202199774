<template>
  <div class="">
    <img
      :src="require('@/assets/host/host.png')"
      alt="Carousel Image"
      style="display: inline-block; width: 100%"
    />
    <!-- 主体部分 -->
    <div class="host">
      <div class="host-title">{{ info.title }}</div>
      <div class="host-content" v-html="info.content"></div>
    </div>
  </div>
</template>

<script>
import { clauseInfo } from "@/api";
export default {
  data() {
    return {
      info: {}
    };
  },
  created(){
    this.getclauseInfo()
  },
  methods: {
    getclauseInfo(){
      clauseInfo().then(res => {
        if(res.code == 1){
          this.info = res.data[0]
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.host {
  width: 1100px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: 20px;
  .host-title {
    width: 100%;
    height: 80px;
    font-size: 20px;
    text-align: center;
    line-height: 80px;
    // border-bottom: 1px solid #ccc;
  }
  .host-com {
    display: block;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
    margin-top: 20px;
  }
  .host-cons {
    font-size: 14px;
    line-height: 28px;
    display: block;
    text-align: left;
    text-indent: 28px;
  }
  .host-content{
    text-align: left;
  }
}
</style>
