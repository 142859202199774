<template>
  <div class="x-off-footer">
    <div class="x-off-footer-nei">
      <!-- 左侧 -->
      <div class="x-off-fleft">
        <div class="x-off-img">
          <el-image
            style="width: 200px; height: 200px"
            :src="require('@/assets/logow.png')"
            fit="contain"
          ></el-image>
        </div>
      </div>
      <!-- 中间 -->
      <div class="x-off-fmid">
        <div class="x-off-fmid-item">
          <span class="x-off-fmid-name">咨询电话： 4000881959</span>
          <!-- <a class="x-off-fmid-a" href="https://beian.miit.gov.cn">豫ICP备2024047646号-1</a> -->
        </div>
      </div>
      <!-- 右侧 -->
      <div class="x-off-fright">
        <div class="x-off-fright-link">
          <router-link to="/about">关于我们</router-link>
          <router-link to="/contactUs">联系我们</router-link>
        </div>
      </div>
      <!-- 最右侧图片 -->
      <div class="x-off-rright">
        <div class="x-off-img">
          <el-image
            style="width: 160px; height: 160px"
            :src="require('@/assets/common/gzh.jpg')"
            fit="contain"
          ></el-image>
          <span>微信公众号</span>
        </div>
        <div class="x-off-img">
          <el-image
            style="width: 160px; height: 160px"
            :src="require('@/assets/common/gzh.jpg')"
            fit="contain"
          ></el-image>
          <span>微信小程序</span>
        </div>
      </div>
    </div>
    <div class="x-off-footer-a">
      <a class="x-off-fmid-a" href="https://beian.miit.gov.cn">豫ICP备2024047646号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.x-off-footer {
  background: #2a2c37;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .x-off-footer-nei {
    margin: 0 auto;
    width: 1448px;
    // height: 100%;
    height: 260px;
    display: flex;
    box-sizing: border-box;
    padding-top: 40px;
    .x-off-fleft {
      width: 300px;
      height: 100%;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .x-off-img{
        width: 200px;
        height: 200px;
      }
    }
    .x-off-fmid {
      width: 360px;
      height: 100%;
      .x-off-fmid-item{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 120px 0 0 0;

        .x-off-fmid-name{
          font-size: 20px;
          text-align: left;
          color: #fff;
          line-height: 20px;
          opacity: 0.8;
        }
        .x-off-fmid-a{
          color: #fff;
          font-size: 14px;
          opacity: 0.8;
          text-decoration: none;
          cursor:pointer;
        }
        .x-off-fmid-time{
          font-size: 12px;
          margin-left: 20px;
          color: #fff;
          line-height: 20px;
          opacity: 0.8;
        }
      }
    }
    .x-off-fright {
      flex: 1;
      height: 100%;
      .x-off-fright-link{
        width: 100%;
        margin-top: 120px;
        display: flex;
        a{
          font-size: 14px;
          color: #fff;
          text-decoration: none;
          line-height: 20px;
          opacity: 0.8;
          margin-left: 40px;
        }
      }
      .x-off-fright-copy{
        width: 100%;
        font-size: 12px;
        color: #fff;
        line-height: 20px;
        opacity: 0.8;
      }
    }
    .x-off-rright{
      width: 440px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .x-off-img{
        width: 200px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        span{
          font-size: 16px;
          line-height: 40px;
          color: #fff;
        }
      }
    }
  }
  .x-off-footer-a{
    width: 100%;
    height: 40px;
    .x-off-fmid-a{
      color: #fff;
      font-size: 14px;
      opacity: 0.8;
      text-decoration: none;
      cursor:pointer;
    }
  }
}
</style>
