<template>
  <div class="gen">
    <div class="gen-top">
      <span class="gen-top-title">商家概况</span>
      <div class="gen-top-info">
        <el-image
          style="width: 20px; height: 22px; margin-right: 10px"
          :src="require('@/assets/icon_name.png')"
          fit="contain"
        ></el-image>
        {{ store_data.name }}
      </div>
    </div>
    <div class="gen-mid">
      <div class="gen-mid-title">车辆库存</div>
      <div class="gen-mid-bx">
        <div class="gen-mid-charts">
          <div class="solid-box">
            <div class="circle-container">
              <div class="circle-item">
                <div class="circle-left-wrap">
                  <div class="cer-left"></div>
                </div>
                <div class="circle-right-wrap">
                  <div class="cer-right" style="transform: rotate(150deg)"></div>
                </div>
                <div class="cer-mask">{{info.turnover_rate}}%</div>
              </div>
            </div>
          </div>

          <div class="mer-left-tip">
            <span></span>
            <span>近30天周转率</span>
          </div>
        </div>
        <div class="gen-mid-info">
          <router-link to="/car" class="gen-order-num" style="width: 386px"
            >库存车辆 <span>{{ info.car_count }}</span></router-link
          >
          <router-link to="/car" class="gen-order-num" style="width: 386px"
            >新入库 <span>{{ info.month_count }}</span></router-link
          >
        </div>
      </div>
    </div>
    <div class="gen-mid">
      <div class="gen-mid-title">订单数量</div>
      <router-link to="/merchantOrder" class="gen-order-num">新增成交 <span>{{ info.await_complete_count }}</span></router-link>
      <router-link to="/merchantOrder" class="gen-order-num">收齐全款 <span>{{ info.complete_time_count }}</span></router-link>
    </div>
  </div>
</template>

<script>
import { indexStoreData } from '@/api'
export default {
  data() {
    return {
      info:{},
      store_data: {}
    };
  },
  created(){
    this.getindexStoreData()
  },
  methods: {
    // 获取商家概况
    getindexStoreData() {
      indexStoreData({
        store_id:window.localStorage.getItem("store_id")
      }).then(res => {
        if (res.code == 1) {
          this.info = res.data
          this.store_data = res.data.store_data
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.gen {
  box-sizing: border-box;
  padding: 20px;
  .gen-top {
    width: 870px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .gen-top-title {
      font-size: 18px;
      color: #f18700;
    }
    .gen-top-info {
      font-size: 14px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .gen-mid {
    width: 870px;
    height: 360px;
    border: 2px solid #e6e6e6;
    border-radius: 8px;
    margin: 0 0 20px;
    background: #fff;
    .gen-mid-title {
      font-size: 18px;
      line-height: 40px;
      text-align: left;
      position: relative;
      box-sizing: border-box;
      padding: 0 10px;
      color: #f18700;
      &::before {
        content: ""; /* 伪元素需要有内容 */
        display: inline-block; /* 使其显示为行内块元素 */
        width: 3px; /* 设置竖线的宽度 */
        height: 22px; /* 设置竖线的高度 */
        background-color: #f18700; /* 设置竖线的颜色 */
        position: absolute;
        top: 9px;
        left: 0px;
      }
    }
    .gen-mid-bx {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .gen-mid-charts {
        flex: 1;
        position: relative;
        .solid-box {
          width: 120px;
          height: 120px;
          margin: 20px auto;
          .circle-container {
            position: relative;
            width: 120px;
            height: 120px;
            .circle-item {
              position: absolute;
              left: 10px;
              top: 10px;
              width: 100px;
              height: 100px;
              border-radius: 50%;
              background-color: #f18700;
              .circle-left-wrap {
                position: absolute;
                left: 0;
                top: 0;
                width: 50px;
                height: 100px;
                overflow: hidden;
                .cer-left {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100px;
                  height: 100px;
                  border-radius: 50%;
                  background: #ddd;
                  clip: rect(0, 50px, auto, 0);
                }
              }
              .circle-right-wrap {
                position: absolute;
                left: 0;
                top: 0;
                width: 50px;
                height: 100px;
                overflow: hidden;
                left: 50px;
                .cer-right {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100px;
                  height: 100px;
                  border-radius: 50%;
                  background: #ddd;
                  clip: rect(0, auto, auto, 50px);
                  left: -50px;
                }
              }
              .cer-mask {
                position: absolute;
                top: 5px;
                left: 5px;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                background-color: #fff;
                text-align: center;
                line-height: 90px;
                color: #f18700;
              }
            }
          }
        }
        .mer-left-tip {
          width: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          span:first-child {
            width: 8px;
            height: 8px;
            background: #f18700;
            border-radius: 50%;
            display: block;
            margin-right: 10px;
          }

          span:last-child {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #3d3d3d;
            line-height: 24px;
          }
        }
      }
      .gen-mid-info {
        width: 386px;
        flex-shrink: 0;
        margin: 0 30px;
      }
    }
    .gen-order-num {
      width: 670px;
      height: 100px;
      margin: 20px auto;
      background: #fff8ef;
      border: 2px solid #ffe9cc;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      text-decoration: none;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #3d3d3d;
      span {
        padding-left: 40px;
        color: #f18700;
      }
    }
  }
}
</style>
