<template>
  <div class="by-content">
    <!-- 图片 -->
    <el-image
      style="width: 1200px; height: 400px"
      :src="require('@/assets/buy/ban.png')"
      fit="contain"
    ></el-image>
    <!-- 购车信息 -->
    <div class="by-mid">
      <div class="by-mid-left">
        <span class="by-mid-title">填写购车信息</span>
        <div class="by-mid-forms">
          <!-- <div class="by-mid-item" style="margin-top: 30px">
            <span class="by-mid-item-title">购车主题</span>
            <span class="by-mid-item-btn activeBtn" style="margin-left: 0">个人</span>
            <span class="by-mid-item-btn">企业</span>
          </div> -->
          <!-- <div class="by-mid-item">
            <span class="by-mid-item-title">上牌城市</span>
            <el-select v-model="value" placeholder="请选择" size="mini" style="width: 180px">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div class="by-mid-tip">
              <span style="color: #f18700">车辆过户需以下证件:</span>
              <span style="color: #f18700">请于购车前准备好以下材料</span>
              <span>1、购车人身份证原件</span>
              <span>2、居住证原件</span>
            </div>
          </div> -->
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            style="width: 50%"
            size="small"
          >
            <el-form-item label="购车主题" prop="buy_body">
              <el-radio-group v-model="ruleForm.buy_body">
                <el-radio-button label="个人"></el-radio-button>
                <el-radio-button label="企业"></el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="购车人姓名" prop="username">
              <el-input v-model="ruleForm.username" placeholder="请输入购车人姓名"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="transfer_agent">
              <el-input v-model="ruleForm.transfer_agent" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="上牌城市" prop="city" style="positon: relative">
              <el-select v-model="ruleForm.city" placeholder="请选择上牌城市" style="width: 100%">
                <el-option
                  v-for="(item,index) in dataCity.city"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <!-- 提示 -->
              <div class="by-mid-tip">
                <div class="triangle"></div>
                <span style="color: #f18700">车辆过户需以下证件:</span>
                <span style="color: #f18700">请于购车前准备好以下材料</span>
                <span>1、购车人身份证原件</span>
                <span>2、居住证原件</span>
              </div>
            </el-form-item>
            <el-form-item label="证件类型" prop="card_type">
              <el-select
                v-model="ruleForm.card_type"
                placeholder="请选择证件类型"
                style="width: 100%"
              >
                <el-option
                  v-for="(item,index) in cardType"
                  :key="index"
                  :label="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证件号码" prop="card_num">
              <el-input v-model="ruleForm.card_num" placeholder="请输入证件号码"></el-input>
            </el-form-item>
            <!-- <el-form-item style="text-align: right">
              <el-button size="small" type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
              <el-button size="small" @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item> -->
          </el-form>
        </div>
      </div>
      <div class="by-mid-right">
        <span class="by-mid-title by-mid-title-ming">购车费用明细</span>
        <div class="by-mid-nei">
          <div class="by-mid-item-s">
            <div class="by-mid-name">商品</div>
            <div class="by-mid-info">
              车款： <span>{{ (info.price * 10000).toFixed(0) }}元</span>
            </div>
          </div>
          <div class="by-mid-item-s">
            <div class="by-mid-name">物流</div>
            <div class="by-mid-info">物流费： <span>1000元</span></div>
          </div>
          <div class="by-mid-item-s">
            <div class="by-mid-name">其他</div>
            <div class="by-mid-info">过户费：<span>1000元</span></div>
          </div>
          <!-- <div class="by-mid-item-s">
            <div class="by-mid-name"></div>
            <div class="by-mid-info">交车保证金（可退）：<span>1,000元</span></div>
          </div> -->
        </div>
        <div class="by-mid-price">
          <span>购车总价：</span>
          <span class="detail-p"
            >{{ ((Number(info.price) + Number(0.2)) * 10000).toFixed(0) }}元</span
          >
        </div>
        <div class="by-mid-price">
          <span>含定金：{{ ((Number(info.price) / 10) * 10000).toFixed(0) }}元</span>
          <span style="margin-left: 20px"
            >尾款：{{
              ((Number(info.price) + Number(0.2) - Number(info.price) / 10) * 10000).toFixed(0)
            }}元</span
          >
        </div>
      </div>
    </div>
    <!-- 购车流程 -->
    <div class="by-mid" style="flex-direction: column">
      <span class="by-mid-title">填写购车信息</span>
      <div class="by-mid-liu">
        <div class="by-liu-item">
          <el-image
            style="width: 98px; height: 98px"
            :src="require('@/assets/buy/buy-bu1.png')"
            fit="contain"
          ></el-image>
          <span class="by-liu-name">支付定金</span>
          <span class="by-liu-title">预定车源，提交购车资料</span>
        </div>
        <div class="by-liu-item">
          <el-image
            style="width: 98px; height: 98px"
            :src="require('@/assets/buy/buy-right.png')"
            fit="contain"
          ></el-image>
        </div>
        <div class="by-liu-item">
          <el-image
            style="width: 98px; height: 98px"
            :src="require('@/assets/buy/buy-bu2.png')"
            fit="contain"
          ></el-image>
          <span class="by-liu-name">车辆运输</span>
          <span class="by-liu-title">检验出库，运输至交付地</span>
        </div>
        <div class="by-liu-item">
          <el-image
            style="width: 98px; height: 98px"
            :src="require('@/assets/buy/buy-right.png')"
            fit="contain"
          ></el-image>
        </div>
        <div class="by-liu-item">
          <el-image
            style="width: 98px; height: 98px"
            :src="require('@/assets/buy/buy-bu3.png')"
            fit="contain"
          ></el-image>
          <span class="by-liu-name">付款提车</span>
          <span class="by-liu-title">看车后支付尾款</span>
        </div>
        <div class="by-liu-item">
          <el-image
            style="width: 98px; height: 98px"
            :src="require('@/assets/buy/buy-right.png')"
            fit="contain"
          ></el-image>
        </div>
        <div class="by-liu-item">
          <el-image
            style="width: 98px; height: 98px"
            :src="require('@/assets/buy/buy-bu4.png')"
            fit="contain"
          ></el-image>
          <span class="by-liu-name">车辆过户</span>
          <span class="by-liu-title">试驾满意办理过户</span>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="by-fot">
      <div class="by-fot-top">
        <span>定金：<span style="color: #f18700">2000元</span></span>
        <el-button
          type="warning"
          round
          @click="submitForm('ruleForm')"
          size="mini"
          style="width: 100px; background: #f18700"
          >立即购买</el-button
        >
      </div>
      <div class="by-fot-top1">
        <el-checkbox v-model="checked">您已阅读并同意</el-checkbox>
        <div class="pay-tiao-content" @click="caluseVisible = true">《二手车交易规则》</div>
      </div>
    </div>
    <!-- 支付 -->
    <el-dialog
      title="请使用微信扫码支付"
      :visible.sync="payVisible"
      width="300px"
      append-to-body
      :before-close="handleClosePay"
    >
      <Pay ref="payMethod" :url="url" v-if="payVisible" :order_num='order_num' :type='2'></Pay>
    </el-dialog>
    <el-dialog
      title="二手车交易规则"
      :visible.sync="caluseVisible"
      width="1000px"
      append-to-body
    >
      <div v-html="infos.content"></div>
    </el-dialog>
  </div>
</template>

<script>
import { indexOrder, wxpay, clauseInfo } from "@/api";
import Pay from "../../user/components/pay.vue";
const  dataCity = require('@/common/city.json')
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    Pay,
  },
  data() {
    return {
      order_num: '',
      dataCity,
      caluseVisible: false,
      payVisible: false,
      url: "",
      checked: false,
      ruleForm: {
        buy_body: "个人",
        logistics_type: '物流'
      },
      cardType: [{
        value:  "身份证"
      },{
        value: '护照'
      }],
      rules: {
        buy_body: [{ required: true, message: "请选择购车主题", trigger: "change" }],
        username: [{ required: true, message: "请输入购车人姓名", trigger: "blur" }],
        transfer_agent: [{ required: true, message: "请输入手机号码", trigger: "blur" },{ pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }],
        city: [{ required: true, message: "请选择上牌城市", trigger: "change" }],
        card_type: [{ required: true, message: "请选择证件类型", trigger: "change" }],
        card_num: [{ required: true, message: "请输入证件号码", trigger: "blur" }],
      },
      infos: {}
    };
  },
  mounted(){
    this.getClauseInfo()
  },
  methods: {
    // 二手车交易规则
    getClauseInfo(){
      clauseInfo().then(res => {
        if(res.code == 1){
          this.infos = res.data[1]
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.checked) {
            this.$message.error("您还未同意《二手车交易规则》");
            return;
          } else {
            let form = this.ruleForm;
            indexOrder({
              id: this.info.id,
              u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
              ...form,
            }).then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.order_num = res.order_num
                this.getWxpay(res.order_num);
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //关闭支付
    handleClosePay() {
      this.payVisible = false;
      this.url = "";
      this.$router.replace({
        path: "/order",
      });
    },
    // 支付
    getWxpay(order_num) {
      let data = {
        order_num: order_num,
        u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
      };
      wxpay(data).then((res) => {
        if (res.code == 1) {
          this.url = res.data;
          this.payVisible = true;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.by-content {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  .by-mid {
    width: 100%;
    height: 360px;
    margin-top: 30px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    .by-mid-title {
      font-size: 18px;
      line-height: 40px;
      text-align: left;
      position: relative;
      box-sizing: border-box;
      padding: 0 15px;
      color: #f18700;
      display: block;
      &::before {
        content: ""; /* 伪元素需要有内容 */
        display: inline-block; /* 使其显示为行内块元素 */
        width: 3px; /* 设置竖线的宽度 */
        height: 22px; /* 设置竖线的高度 */
        background-color: #f18700; /* 设置竖线的颜色 */
        position: absolute;
        top: 9px;
        left: 0px;
      }
    }
    .by-mid-title-ming {
      font-size: 18px;
      line-height: 40px;
      text-align: left;
      position: relative;
      box-sizing: border-box;
      padding: 0 15px;
      color: #f18700;
      display: block;
      &::before {
        content: ""; /* 伪元素需要有内容 */
        display: inline-block; /* 使其显示为行内块元素 */
        width: 3px; /* 设置竖线的宽度 */
        height: 22px; /* 设置竖线的高度 */
        background-color: #f18700; /* 设置竖线的颜色 */
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
    .by-mid-left {
      flex: 1;
      .by-mid-forms {
        width: 100%;
        /deep/.el-radio-button {
          margin-right: 15px;
        }
        /deep/.el-radio-button__inner {
          border-radius: 4px;
          border-left: 1px solid #dcdfe6;
        }
        .by-mid-tip {
          position: absolute;
          top: 0;
          right: -334px;
          background-color: rgba(255, 248, 240, 1);
          border-radius: 0 6px 6px 6px;
          width: 320px;
          height: 120px;
          box-sizing: border-box;
          padding: 5px 20px;
          .triangle {
            position: absolute;
            left: -7px;
            top: 1px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 7px 5px 0; /* 上右下左 */
            border-color: transparent #fff8f0 transparent transparent; /* 上右下左颜色 */
            //  border-color: transparent transparent #FFF8F0 transparent; /* 左上右下颜色 */
          }
          span {
            display: block;
            font-size: 14px;
            font-family: SourceHanSansCN-Medium;
            line-height: 28px;
            text-align: left;
          }
        }
        .by-mid-item {
          display: flex;
          box-sizing: border-box;
          padding: 0 56px;
          margin-bottom: 24px;
          .by-mid-item-title {
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 14px;
            font-family: SourceHanSansCN-Medium;
            white-space: nowrap;
            line-height: 28px;
            margin-right: 10px;
          }
          .by-mid-item-btn {
            box-shadow: 0px 2px 6px 0px rgba(240, 243, 250, 1);
            border-radius: 15px;
            height: 26px;
            border: 1px solid #e6e6e6;
            margin-left: 21px;
            width: 78px;
            font-size: 14px;
            text-align: center;
            line-height: 26px;
          }
          .activeBtn {
            color: #f18700;
            border-color: #f18700;
          }
        }
      }
    }
    .by-mid-right {
      border-radius: 4px;
      width: 420px;
      height: 290px;
      border: 1px solid #e6e6e6;
      margin: 20px 0 0 0;
      flex-shrink: 0;
      padding: 20px 20px 0;
      .by-mid-title {
        font-size: 16px;
        font-family: SourceHanSansCN-Bold;
        text-align: left;
        white-space: nowrap;
        line-height: 24px;
        display: block;
        margin-bottom: 20px;
      }
      .by-mid-nei {
        width: 100%;
        height: 160px;
        border-bottom: 2px solid #dee2e7;
        .by-mid-item-s {
          display: flex;
          height: 35px;
          width: 100%;
          align-items: center;
          font-size: 14px;
          color: #333;
          .by-mid-name {
            font-size: 14px;
            width: 30%;
            text-align: left;
            .by-mid-info {
              font-size: 14px;
              span {
                color: #000;
                font-size: 14px;
              }
            }
          }
        }
      }
      .by-mid-price {
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        span {
          font-size: 14px;
        }
        .detail-p {
          font-size: 18px;
          color: #f18700;
        }
      }
    }
    .by-mid-liu {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      padding: 64px 131px;
      .by-liu-item {
        flex: 1;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .by-liu-name {
          font-size: 18px;
          font-family: SourceHanSansSC-Medium;
          text-align: center;
          white-space: nowrap;
          line-height: 36px;
          margin-top: 24px;
        }
        .by-liu-title {
          font-size: 14px;
          font-family: SourceHanSansSC-Medium;
          text-align: center;
          white-space: nowrap;
          line-height: 36px;
          color: #666;
        }
      }
    }
  }
  .by-fot {
    width: 100%;
    height: 170px;
    box-sizing: border-box;
    padding: 40px;
    .by-fot-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      span {
        font-size: 18px;
      }

    }
    .by-fot-top1{
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      .pay-tiao-content {
        display: inline-block;
        color: #F18700;
        cursor: pointer;
      }
    }
  }
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  box-shadow: 0 0 0 0 #f18700;
}
</style>
