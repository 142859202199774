<template>
  <div class="">
    <el-dialog
      title="提示"
      :visible.sync="visible"
      width="700px"
      :before-close="handleClose"
      append-to-body
      size='small'
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        size='small'
      >
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="车辆品牌" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入车辆品牌"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车辆型号" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入车辆型号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="上牌时间" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入上牌时间"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="价格" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入价格"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="表显里程" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入表显里程"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排量" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入排量"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="变速箱" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入变速箱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="新车含税价" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入新车含税价"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="过户次数" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入过户次数"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌归属地" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入车牌归属地"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="车身颜色" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入车身颜色"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出场日期" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入出场日期"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="钥匙数量" prop="realname">
              <el-input v-model="ruleForm.realname" placeholder="请输入钥匙数量"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"> </el-col>
        </el-row>

        <el-form-item style="text-align: right">
          <el-button size="small" type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <el-button size="small" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { addressAdd, addressUpdate } from "@/api";
const dataCity = require("@/common/city.json");
import { validatenull } from "@/common/common.js";
export default {
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      dataCity,
      value: [],
      ruleForm: {
        realname: "",
        mobile: "",
        address: "",
        province: "",
        city: "",
        area: "",
        address_type: "",
        is_default: 0,
      },
      rules: {
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          //{ pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
        ],
        province: [{ required: true, message: "请选择省市县", trigger: "change" }],
        address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        address_type: [{ required: true, message: "请选择地址类型", trigger: "change" }],
      },
      id: "",
    };
  },
  mounted() {
    if (!validatenull(this.record)) {
      this.id = this.record.id;
      this.value = [this.record.province, this.record.city, this.record.area];
      this.ruleForm = this.record;
    }
  },
  methods: {
    // 打开弹窗
    openDialog() {
      this.visible = true;
    },
    // 点击知道了
    handleClose() {
      this.visible = false;
      this.$emit("wareMes");
    },

    //获取省市县
    handleChange(value) {
      this.ruleForm.province = value[0];
      this.ruleForm.city = value[1];
      this.ruleForm.area = value[2];
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.ruleForm;
          if (this.id) {
            addressUpdate({
              address_id: this.id,
              u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
              ...form,
            }).then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.$emit("ok");
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            addressAdd({
              u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
              ...form,
            }).then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.$emit("ok");
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.value = [];
    },
  },
};
</script>
<style lang="scss" scoped>
  /deep/.el-dialog__body{
    padding: 10px 20px;
  }
</style>
