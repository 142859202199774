<!--足迹-->
<template>
  <el-card>
    <div class="search-form" style="text-align: left">
      <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="70px" label-position="left"
        class="demo-form-inline" size="small">
        <el-form-item label="车辆名称">
          <el-input clearable size="small" v-model="searchForm.car_name" placeholder="请输入车辆名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
          <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 模拟列表 -->
    <div class="tb-box">
      <div class="tb-item" v-for="(item,index) in dataList" :key="index" @click="goToDetail(item.car_id)">
        <div class="tb-item-left">
          <el-image :src='item.car.exterior_images[0]' class="tb-item-img" fit="contain"></el-image>
        </div>
        <div class="tb-item-mid">
          <span class="tb-item-title">{{ item.car.car_name }}</span>
          <span class="tb-item-price">{{ item.car.price }}万</span>
        </div>
        <div class="tb-item-right">
          <el-button type="warning" round @click.stop="handleCancel(item.car_id)" size='mini' style="width: 100px;background:#F18700">删除</el-button>
        </div>
      </div>
      <el-empty description="暂无足迹~" v-if="dataList.length == 0"></el-empty>
    </div>
    <div class="pagization-box">
      <CustomPagization :background="true" :currentPage="current" :total="total" :pageSize="size"
        @current-change="currentChange" />
    </div>
    <!-- 详情 -->
  </el-card>
</template>

<script>
import { indexTrackList, indexTrackDel } from '@/api'
export default {
  data() {
    return {
      searchForm: {
        car_name: ""
      },
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
    };
  },
  created() {
    this.getInit();
  },
  methods: {
    // 前往详情页面
    goToDetail(id){
      this.$router.push({
        path: '/detail',
        query: {
          id:id,
          name: '我的足迹'
        },
      });
    },
    // 打开详情
    handleDetail(){
      this.$refs.detail.openDialog()
    },
    //删除足迹
    handleCancel(id) {
      this.$confirm("确认删除足迹吗?", "温馨提示", {
        type: "warning",
      })
        .then(() => {
          indexTrackDel({ 
            u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
            id: id
          }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg);
              this.getInit();
            }else{
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 获取列表
    getInit(){
      indexTrackList({
        u_id:JSON.parse(window.localStorage.getItem("userInfo")).id,
        page: this.current,
        limit: this.size,
        car_name: this.searchForm.car_name
      }).then(res => {
        if(res.code == 1){
          this.dataList = res.data.data
          this.total = res.data.total
        }
      })
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getInit();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.getInit();
    }
  },
};
</script>

<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
</style>
