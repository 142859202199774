import Vue from 'vue';
import VueRouter from 'vue-router';

// 前台页面
import Index from '@/layout/before/index'; // 官网首页-框架
import Buy from '@/views/before/buy'; // 我要买车
import Sell from '@/views/before/sell'; // 我要卖车
import Host from '@/views/before/host'; // 我要入驻
import Detail from '@/views/before/detail'; // 车辆详情
import Home from '@/views/before/index'; // 首页
import About from '@/views/before/about'; // 关于我们
import ContactUs from '@/views/before/contactUs'; // 联系我们

// 后台页面
import Layout from '@/layout/after/index'; // 后台首页
import Order from '@/views/user/order'; // 订单列表
import Appoint from '@/views/user/appoint'; // 预约看车
import Car from '@/views/user/car'; // 车辆管理
import Collect from '@/views/user/collect'; // 收藏 
import FootPrint from '@/views/user/footprint'; // 足迹
import General from '@/views/user/general'; // 商家概况
import MerchantOrder from '@/views/user/merchantOrder'; // 商家订单
import MyBargain from '@/views/user/myBargain'; // 我的砍价
import ReceiveBargain from '@/views/user/receiveBargain'; // 收到砍价



// 单独页面
import Login from '@/views/login/index'; // 登录
import Register from '@/views/register/index'; // 注册
Vue.use(VueRouter);

const routes = [
  // 前台页面
  {
    path: '',
    component: Index,
    children:[
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta:{
          title: '迈车界二手车'
        }
      },
      {
        path: '/buy',
        name: 'Buy',
        component: Buy,
        meta:{
          title: '我要买车'
        }
      },
      {
        path: '/sell',
        name: 'Sell',
        component: Sell,
        meta:{
          title: '我要卖车'
        }
      },
      {
        path: '/host',
        name: 'Host',
        component: Host,
        meta:{
          title: '我要入驻'
        }
      },
      {
        path: '/detail',
        name: 'Detail',
        component: Detail,
        meta:{
          title: '车辆详情'
        }
      },
      {
        path: '/about',
        name: 'About',
        component: About,
        meta:{
          title: '关于我们'
        }
      },
      {
        path: '/contactUs',
        name: 'ContactUs',
        component: ContactUs,
        meta:{
          title: '联系我们'
        }
      }
    ]
  },
  // 后台页面
  {
    path: '/layout',
    component: Layout,
    redirect: '/order',
    children:[ 
      {
        path: '/order',
        name: 'Order',
        component: Order,
        meta:{
          title: '我的订单'
        }
      }, 
      {
        path: '/appoint',
        name: 'Appoint',
        component: Appoint,
        meta:{
          title: '预约看车',
          iscompany: 2
        }
      }, 
      {
        path: '/car',
        name: 'Car',
        component: Car,
        meta:{
          title: '车辆管理',
          iscompany: 2
        }
      }, 
      {
        path: '/collect',
        name: 'Collect',
        component: Collect,
        meta:{
          title: '我的收藏'
        }
      }, 
      {
        path: '/footprint',
        name: 'FootPrint',
        component: FootPrint,
        meta:{
          title: '我的足迹'
        }
      }, 
      {
        path: '/general',
        name: 'General',
        component: General,
        meta:{
          title: '商家概况',
          iscompany: 2
        }
      }, 
      {
        path: '/merchantOrder',
        name: 'MerchantOrder',
        component: MerchantOrder,
        meta:{
          title: '商家订单',
          iscompany: 2
        }
      }, 
      {
        path: '/myBargain',
        name: 'MyBargain',
        component: MyBargain,
        meta:{
          title: '我的砍价'
        }
      }, 
      {
        path: '/receiveBargain',
        name: 'ReceiveBargain',
        component: ReceiveBargain,
        meta:{
          title: '收到砍价',
          iscompany: 2
        }
      }
    ]
  },
  // 单独页面
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta:{
      title: '注册'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta:{
      title: '关于我们'
    }
  }, 
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
