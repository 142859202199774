<template>
  <div class="">
    <!-- 轮播图 -->
    <el-carousel indicator-position="outside" style="height: 400px">
      <el-carousel-item v-for="(item, index) in banner" :key="index" style="height: 400px">
        <img :src="item.image" />
      </el-carousel-item>
    </el-carousel>
    <!-- 中间内容 -->
    <div class="home">
      <div class="sell-input">
        <el-input placeholder="请搜索车辆信息" v-model="keyword">
          <template slot="append">
            <div @click="handleEstimate(1)" class="input-btn">快速估价</div>
          </template>
        </el-input>
      </div>
      <!-- 我要买车 -->
      <div class="home-sell">
        <span class="home-title">我要买车</span>
        <div class="home-type">
          <div
            class="home-type-item"
            v-for="(item, index) in pinAllList"
            :key="index"
            :style="item.name == '凯迪拉克' ? 'width: 110px' : ''"
            @click="handleEstimate(2, item.id)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="home-price">
          <span
            class="home-price-item"
            v-for="(item1, index1) in priceList"
            :key="index1"
            @click="handleEstimate(3, item.id)"
            >{{ item1.name }}</span
          >
        </div>
      </div>
      <!-- 热卖车型 -->
      <div class="home-hot">
        <span class="home-title">热卖车型</span>
        <div class="home-nav">
          <span
            :class="labelNum == index ? 'active' : ''"
            v-for="(item, index) in labelList"
            :key="index"
            @click="changeLabel(item.id)"
            >{{ item.name }}</span
          >
        </div>
        <!-- 内容 -->
        <div class="home-hot-content">
          <div class="home-hot-item" v-for="(item, index) in dataList" :key="index" @click="goToDetail(item.id)">
            <div class="home-hot-item-content">
              <el-image
                style="width: 334px; height: 232px"
                :src="item.exterior_images[0]"
                fit="contain"
              ></el-image>
              <div class="neis">
                <span class="neis-title">{{ item.car_name }}</span>
                <div class="neis-time">
                  <span style="margin-right: 5px">{{ item.license_tag }}年</span> |
                  <span style="margin-left: 5px">{{ item.show_mileage }}万公里</span>
                </div>
                <div class="neis-pice">
                  <span style="font-size: 18px">{{ item.price }}</span
                  >万
                  <span style="margin-left: 5px">首付{{ item.down_payment_price }}万</span>
                </div>
              </div>
            </div>
          </div>

          <el-empty description="暂无车辆信息~" v-if="dataList.length == 0"></el-empty>
        </div>
        <div class="pagization-box">
          <CustomPagization
            :background="true"
            :currentPage="current"
            :total="total"
            :pageSize="size"
            @current-change="currentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { indexBannerLabel, indexIndexList } from "@/api";
export default {
  data() {
    return {
      keyword: "",
      showMore: true,
      banner: [
        {
          url: require("@/assets/banner/1.jpg"),
        },
        {
          url: require("@/assets/banner/2.jpg"),
        },
        {
          url: require("@/assets/banner/3.jpg"),
        },
        {
          url: require("@/assets/banner/4.jpg"),
        },
      ],
      // typeList: [
      //   {
      //     id: 1,
      //     url: require("@/assets/icon/101.png"),
      //     name: "大众1",
      //   },
      //   {
      //     id: 2,
      //     url: require("@/assets/icon/102.png"),
      //     name: "本田",
      //   },
      //   {
      //     id: 3,
      //     url: require("@/assets/icon/103.png"),
      //     name: "宝马",
      //   },
      //   {
      //     id: 4,
      //     url: require("@/assets/icon/104.png"),
      //     name: "丰田",
      //   },
      //   {
      //     id: 5,
      //     url: require("@/assets/icon/105.png"),
      //     name: "奥迪",
      //   },
      //   {
      //     id: 6,
      //     url: require("@/assets/icon/106.png"),
      //     name: "奔驰",
      //   },
      //   {
      //     id: 7,
      //     url: require("@/assets/icon/107.png"),
      //     name: "保时捷",
      //   },
      //   {
      //     id: 8,
      //     url: require("@/assets/icon/108.png"),
      //     name: "沃尔沃",
      //   },
      //   {
      //     id: 9,
      //     url: require("@/assets/icon/109.png"),
      //     name: "路虎",
      //   },
      // ],
      priceList: [
        {
          id: 1,
          name: "3万以下",
          min: 0,
          max: 3,
        },
        {
          id: 2,
          name: "3-5万",
          min: 3,
          max: 5,
        },
        {
          id: 3,
          name: "5-8万",
          min: 5,
          max: 8,
        },
        {
          id: 4,
          name: "8-10万",
          min: 8,
          max: 10,
        },
        {
          id: 5,
          name: "10-15万",
          min: 10,
          max: 15,
        },
        {
          id: 6,
          name: "15-20万",
          min: 15,
          max: 20,
        },
        {
          id: 7,
          name: "20-30万",
          min: 20,
          max: 30,
        },
        {
          id: 8,
          name: "30-50万",
          min: 30,
          max: 50,
        },
        {
          id: 9,
          name: "50-100万",
          min: 50,
          max: 100,
        },
        {
          id: 10,
          name: "100万+",
          min: 100,
          max: 1000,
        },
      ],
      pinList: [
        // {
        //   id: 0,
        //   url: "@/assets/common/gzh.jpg",
        //   name: "不限",
        // },
        {
          id: 1,
          url: "@/assets/common/gzh.jpg",
          name: "大众",
        },
        {
          id: 2,
          url: "@/assets/common/gzh.jpg",
          name: "本田",
        },
        {
          id: 3,
          url: "@/assets/common/gzh.jpg",
          name: "宝马",
        },
        {
          id: 4,
          url: "@/assets/common/gzh.jpg",
          name: "丰田",
        },
        {
          id: 5,
          url: "@/assets/common/gzh.jpg",
          name: "奥迪",
        },
        {
          id: 6,
          url: "@/assets/common/gzh.jpg",
          name: "奔驰",
        },
        {
          id: 7,
          url: "@/assets/common/gzh.jpg",
          name: "保时捷",
        },
        {
          id: 8,
          url: "@/assets/common/gzh.jpg",
          name: "沃尔沃",
        },
        {
          id: 9,
          url: "@/assets/common/gzh.jpg",
          name: "路虎",
        },
        {
          id: 10,
          url: "@/assets/common/gzh.jpg",
          name: "小米",
        },
        {
          id: 11,
          url: "@/assets/common/gzh.jpg",
          name: "比亚迪",
        },
        {
          id: 12,
          url: "@/assets/common/gzh.jpg",
          name: "长城",
        },
        {
          id: 13,
          url: "@/assets/common/gzh.jpg",
          name: "长安",
        },
        {
          id: 14,
          url: "@/assets/common/gzh.jpg",
          name: "本田",
        },
        {
          id: 15,
          url: "@/assets/common/gzh.jpg",
          name: "别克",
        },
        {
          id: 16,
          url: "@/assets/common/gzh.jpg",
          name: "福特",
        },
        {
          id: 17,
          url: "@/assets/common/gzh.jpg",
          name: "特斯拉",
        },
        {
          id: 18,
          url: "@/assets/common/gzh.jpg",
          name: "哈佛",
        },
        {
          id: 19,
          url: "@/assets/common/gzh.jpg",
          name: "悍马",
        },
        {
          id: 20,
          url: "@/assets/common/gzh.jpg",
          name: "吉利",
        },
      ],
      pinAllList: [
        {
          id: 1,
          url: "@/assets/common/gzh.jpg",
          name: "大众",
        },
        {
          id: 2,
          url: "@/assets/common/gzh.jpg",
          name: "本田",
        },
        {
          id: 3,
          url: "@/assets/common/gzh.jpg",
          name: "宝马",
        },
        {
          id: 4,
          url: "@/assets/common/gzh.jpg",
          name: "丰田",
        },
        {
          id: 5,
          url: "@/assets/common/gzh.jpg",
          name: "奥迪",
        },
        {
          id: 6,
          url: "@/assets/common/gzh.jpg",
          name: "奔驰",
        },
        {
          id: 7,
          url: "@/assets/common/gzh.jpg",
          name: "保时捷",
        },
        {
          id: 8,
          url: "@/assets/common/gzh.jpg",
          name: "沃尔沃",
        },
        {
          id: 9,
          url: "@/assets/common/gzh.jpg",
          name: "路虎",
        },
        {
          id: 10,
          url: "@/assets/common/gzh.jpg",
          name: "小米",
        },
        {
          id: 11,
          url: "@/assets/common/gzh.jpg",
          name: "比亚迪",
        },
        {
          id: 12,
          url: "@/assets/common/gzh.jpg",
          name: "长城",
        },
        {
          id: 13,
          url: "@/assets/common/gzh.jpg",
          name: "长安",
        },
        {
          id: 14,
          url: "@/assets/common/gzh.jpg",
          name: "本田",
        },
        {
          id: 15,
          url: "@/assets/common/gzh.jpg",
          name: "别克",
        },
        {
          id: 16,
          url: "@/assets/common/gzh.jpg",
          name: "福特",
        },
        {
          id: 17,
          url: "@/assets/common/gzh.jpg",
          name: "特斯拉",
        },
        {
          id: 18,
          url: "@/assets/common/gzh.jpg",
          name: "哈佛",
        },
        {
          id: 19,
          url: "@/assets/common/gzh.jpg",
          name: "悍马",
        },
        {
          id: 20,
          url: "@/assets/common/gzh.jpg",
          name: "吉利",
        },
        {
          id: 21,
          url: "@/assets/common/gzh.jpg",
          name: "凯迪拉克",
        },
        {
          id: 22,
          url: "@/assets/common/gzh.jpg",
          name: "理想",
        },
        {
          id: 23,
          url: "@/assets/common/gzh.jpg",
          name: "林肯",
        },
        {
          id: 24,
          url: "@/assets/common/gzh.jpg",
          name: "马自达",
        },
        {
          id: 25,
          url: "@/assets/common/gzh.jpg",
          name: "奇瑞",
        },
        {
          id: 26,
          url: "@/assets/common/gzh.jpg",
          name: "蔚蓝",
        },        
        {
          id: 27,
          url: "@/assets/common/gzh.jpg",
          name: "现代",
        },
      ],
      labelList: [
        {
          id: 0,
          name: "全部",
        },
      ],
      labelNum: 0,
      current: 1,
      size: 12,
      total: 1,
      dataList: [],
      isDetail: true, // 是否详情页返回的
      path: ''
    };
  },

  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     // 通过回调函数访问组件实例
  //     if(from.path == '/detail'){
  //       vm.isDetail = true;
  //     }else{
  //       vm.isDetail = false;
  //     }
  //   });
  // },
  created() {
    this.getIndexBannerLabel();
    this.getIndexCarList();
  },
  // mounted(){
  //   console.log(this.isDetail,"d")
  //   if(!this.isDetail){
  //     this.getIndexCarList();
  //   }
  // },
  methods: {
    // 前往详情页面
    goToDetail(id){
      this.$router.push({
        path: '/detail',
        query: {
          id:id,
          name: '首页'
        },
      });
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getIndexCarList();
    },
    // 获取首页导航
    getIndexBannerLabel() {
      indexBannerLabel().then((res) => {
        if (res.code == 1) {
          this.labelList = [
            {
              id: 0,
              name: "全部",
            },
          ];
          this.labelList = this.labelList.concat(res.label);
          this.banner = res.banner
        }
      });
    },
    // 切换导航
    changeLabel(num) {
      this.labelNum = num;
      this.current = 1;
      this.getIndexCarList();
    },
    // 获取首页车辆数据
    getIndexCarList() {
      indexIndexList({
        u_id: window.localStorage.getItem("userInfo")
          ? JSON.parse(window.localStorage.getItem("userInfo")).id
          : 0,
        page: this.current,
        limit: this.size,
        label_id: this.labelNum,
      }).then((res) => {
        if (res.code == 1) {
          this.dataList = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    // 跳转买车页面
    handleEstimate(id, num) {
      if (id == 1) {
        this.$router.push({
          path: "/buy",
          query: {
            car_name: this.keyword,
          },
        });
      } else if (id == 2) {
        this.$router.push({
          path: "/buy",
          query: {
            brand: num,
          },
        });
      } else if (id == 3) {
        this.$router.push({
          path: "/buy",
          query: {
            price: num,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 1448px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 40px;
  .home-title {
    display: block;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    box-sizing: border-box;
    padding: 20px 0 30px;
  }
  .sell-input {
    width: 788px;
    height: 40px;
    margin: 0 auto 60px;
    display: flex;
    align-items: center;
    .input-btn {
      display: block;
      width: 97px;
      height: 38px;
      border-radius: 0 8px 8px 0;
      line-height: 38px;
      cursor: pointer;
    }
    /deep/.el-input-group {
      border: 1px solid #f18700;
      border-radius: 8px;
    }
    /deep/.el-input__inner {
      background: #f5f5f5;
      border-radius: 8px 0 0 8px;
    }
    /deep/.el-input-group__append {
      border-radius: 0 8px 8px 0;
      background: #f18700;
      color: #fff;
      padding: 0 !important;
    }
  }
  .home-sell {
    width: 100%;
    height: 232px;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;
    .home-type {
      width: 100%;
      height: 50px;
      display: flex;
      position: relative;
      .home-type-item {
        width: 100px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        span {
          font-size: 14px !important;
        }
      }
      .buy-more{
        position: absolute;
        right: 0;
        color: #f18700;
        font-size: 14px;
      }
    }
    .home-price {
      width: 100%;
      height: 50px;
      display: flex;
      cursor: pointer;
      .home-price-item {
        width: 100px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px !important;
      }
    }
  }
  .home-hot {
    .home-nav {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        padding: 0 10px;
        color: #666;
        cursor: pointer;
      }
      .active {
        color: #f18700;
      }
    }
  }
}
</style>
