<template>
  <div>
    <div class="" @click="sta">注册</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    sta() {
      let res = {
        code: 1,
        msg: "登录成功",
        data: {
          id: 5,
          username: "17864285718",
          mobile: "17864285718",
          avatar: "",
          gender: 0,
        },
      };
      window.localStorage.setItem("userInfo", JSON.stringify(res.data));
      //  window.localStorage.setItem("store_id", 4);
      console.log(window.localStorage.getItem("userInfo"));
    },
  },
};
</script>
<style lang="scss" scoped></style>
