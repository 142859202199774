<template>
  <div class="login-container">
    <div class="background-image"></div>
    <div class="login-form">
      <el-image
        style="width: 75px; height: 36px;margin: 35px auto 15px;"
        :src="require('@/assets/logo.png')"
        fit="contain"
      ></el-image>
      <p class="login-tip">手机号快捷登录</p>
      <!-- 在这里添加登录表单的内容 -->
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item prop="phone">
          <el-input v-model="ruleForm.phone" autocomplete="off" clearable placeholder="请输入手机号">
            <span slot="prefix">+86</span>
          </el-input>
        </el-form-item>
        <el-form-item prop="smsCode" style="display: flex;">
          <el-input v-model="ruleForm.smsCode" autocomplete="off" clearable placeholder="请输入验证码" style="width: 190px"></el-input>
          <div class="code" v-if='codeCd'>获取验证码({{ long }})</div>
          <div class="code" v-else @click="sendPhoneCode('ruleForm')">获取验证码</div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')" style="width: 100%">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { usersSendSms, usersSmsLogin } from '@/api'
export default {
  // 在这里添加组件的逻辑和方法
  data() {
    return {
      ruleForm: {
        phone: "",
        smsCode: "",
      },
      long: 60,
      codeCd: false,
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { pattern: /^\d{4}$/, message: '验证码格式不正确', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    // 发送验证码
    sendPhoneCode(formName){
      this.$refs[formName].validateField('phone', async (valid) => {
        if (!valid) {
          // 获取验证码
          this.getusersSendSms()
          // 开启计时
          this.codeCd = true
          const timer = setInterval(() => {
            this.long--
            if (this.long <= 0) {
              this.long = 60
              this.codeCd = false
              clearInterval(timer)
            }
          }, 1000)
        } else {
          return false
        }
      })
    },
    // 获取验证码
    getusersSendSms(){
      usersSendSms({phone:this.ruleForm.phone}).then(res => {
        if(res.code == 1){
          // this.phoneCode = res.data
          this.$message.success(res.msg)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    // 登录/注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          usersSmsLogin(this.ruleForm).then(res => {
            if(res.code == 1){
              window.localStorage.setItem("userInfo",JSON.stringify(res.data))
              this.$message.success(res.msg)
              this.$router.push({ path: '/order'});
            }else{
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/bg.jpg");
  background-size: cover;
  /* opacity: 0.5; */
}

.login-form {
  width: 400px;
  height: 400px;
  z-index: 9999;
  box-sizing: border-box;
  padding: 0 40px;
  background:#fff !important;
  border-radius: 8px;
  .code{
    font-size: 14px;
    cursor: pointer;
    color: #F18700;
    margin-left: 10px;
    flex: 1;
    text-align: right;
    width: 100px;
    float: right;
  }
  .login-tip{
    width: 100%;
    font-size: 14px;
    padding: 0;
    margin: 0;
    height: 30px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
  }
  .demo-ruleForm{
    width: 300px;
    height: 300px;
  }
}

/deep/.el-input--prefix .el-input__inner{
  padding-left: 40px !important;
}
</style>
