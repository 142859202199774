<template>
  <div class="banshi">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="swiper-slide"
          :style="`background-image:url(${item})`"
          @mouseenter="slideTo(index,item)"
          :class="{ active: index === activeSlideIndex }"
        ></div>
      </div>
      <!-- <div class="swiper-pagination"></div> -->
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
</template>

<script>
// import Swiper from 'swiper'; // 注意引入的是Swiper
// import 'swiper/css/swiper.min.css' // 注意这里的引入
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      swiper: null,
      activeSlideIndex: 0, // 初始活动幻灯片索引
      // swiperList: [
      //   {
      //     id: 1,
      //     title: "采集国家二级保护野生植物审批",
      //     imgUrl:
      //       "https://img0.baidu.com/it/u=2639758887,1655012884&fm=253&fmt=auto&app=138&f=PNG?w=632&h=500",
      //   },
      //   {
      //     id: 2,
      //     title: "农村危房改造",
      //     imgUrl:
      //       "https://img0.baidu.com/it/u=2639758887,1655012884&fm=253&fmt=auto&app=138&f=PNG?w=632&h=500",
      //   },
      //   {
      //     id: 3,
      //     title: "农村危房改造",
      //     imgUrl:
      //       "https://img0.baidu.com/it/u=2639758887,1655012884&fm=253&fmt=auto&app=138&f=PNG?w=632&h=500",
      //   },
      //   {
      //     id: 4,
      //     title: "农村危房改造",
      //     imgUrl:
      //       "https://img0.baidu.com/it/u=2639758887,1655012884&fm=253&fmt=auto&app=138&f=PNG?w=632&h=500",
      //   },
      //   {
      //     id: 5,
      //     title: "采集国家二级保护野生植物审批",
      //     imgUrl:
      //       "https://img0.baidu.com/it/u=2639758887,1655012884&fm=253&fmt=auto&app=138&f=PNG?w=632&h=500",
      //   },
      //   {
      //     id: 6,
      //     title: "农村危房改造",
      //     imgUrl:
      //       "https://img0.baidu.com/it/u=2639758887,1655012884&fm=253&fmt=auto&app=138&f=PNG?w=632&h=500",
      //   },
      //   {
      //     id: 7,
      //     title: "农村危房改造",
      //     imgUrl:
      //       "https://img0.baidu.com/it/u=2639758887,1655012884&fm=253&fmt=auto&app=138&f=PNG?w=632&h=500",
      //   },
      //   {
      //     id: 8,
      //     title: "农村危房改造",
      //     imgUrl:
      //       "https://img0.baidu.com/it/u=2639758887,1655012884&fm=253&fmt=auto&app=138&f=PNG?w=632&h=500",
      //   },
      // ],
    };
  },
  mounted() {
    this.getSwiper();
  },
  methods: {
    getSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        // loop: true, // 无缝
        // autoplay: {
        //   //自动开始
        //   delay: 3000, //时间间隔
        //   disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        // },
        paginationClickable: true,
        slidesPerView: 5, // 一组三个
        spaceBetween: 5, // 间隔
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
        observer: true,
        observeParents: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 分页器可以点击
        },
      });
    },
    slideTo(index,url) {
      if (this.swiper) {
        this.swiper.slideTo(index);
        // 确保所有幻灯片都没有 active 类
        this.swiper.slides.removeClass("active");
        // 给当前幻灯片添加 active 类
        const slideEl = this.swiper.slides.eq(index);
        slideEl.addClass("active");
        this.activeSlideIndex = index;
        this.$emit('changeUrl',url)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.banshi {
  width: 726px;
  height: 92px;
  .swiper-slide {
    position: relative;
    height: 92px;
    width: 137px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #f7f8fa;
    background-size: 100% 100%;
    .el-button {
      z-index: 2;
    }
  }
  .active {
    border: 1px solid #f18700; /* 举例，给活动幻灯片添加蓝色边框 */
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
    margin-top: -11px;
  }
  .swiper-button-prev{
     background-image: url("../../../assets/common/left.png");
  }
  .swiper-button-next{
    background-image: url("../../../assets/common/right.png");
  }
  .swiper-pagination {
    bottom: 0;
  }
}
</style>
