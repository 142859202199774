<template>
  <div class="x-tops" :style="{ width: isCollapse ? '60px' : '230px' }">
    <div class="x-title">
      <span v-if="isCollapse">迈</span>
      <span v-else>
        <img
          :src="require('@/assets/logo.png')"
          alt="Carousel Image"
          class="logo-img"
          @click="goToIndex"
        />
      </span>
    </div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      background-color="#F18700"
      text-color="#fff"
      active-text-color="#fff"
      router
    >
      <div v-for="(item, index) in nav" :key="index">
        <el-submenu :index="item.url" v-if="item.ischildren">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title" v-if="!isCollapse">{{ item.name }}</span>
          </template>
          <el-menu-item :index="item1.url" v-for="(item1, index1) in item.children" :key="index1">{{
            item1.name
          }}</el-menu-item>
        </el-submenu>
        <el-menu-item :index="item.url" v-else>
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </div>
      <!-- <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span slot="title">导航一</span>
        </template>
          <el-menu-item index="/ce1">选项1</el-menu-item>
          <el-menu-item index="/ce2">选项2</el-menu-item>
      </el-submenu>
      <el-menu-item index="/">
        <i class="el-icon-setting"></i>
        <span slot="title">关于</span>
      </el-menu-item>
      <el-menu-item index="/home">
        <i class="el-icon-setting"></i>
        <span slot="title">首页</span>
      </el-menu-item> -->
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      nav: [
        {
          uid: 1,
          icon: "el-icon-document",
          name: "我的订单",
          url: "/order",
          ischildren: false,
        },
        {
          uid: 2,
          icon: "el-icon-tickets",
          name: "商家订单",
          url: "/merchantOrder",
          ischildren: false,
        },
        {
          uid: 3,
          icon: "el-icon-star-off",
          name: "我的收藏",
          url: "/collect",
          ischildren: false,
        },
        {
          uid: 4,
          icon: "el-icon-place",
          name: "我的足迹",
          url: "/footprint",
          ischildren: false,
        },
        {
          uid: 5,
          icon: "el-icon-sold-out",
          name: "我的砍价",
          url: "/myBargain",
          ischildren: false,
        },
        {
          uid: 6,
          icon: "el-icon-sell",
          name: "收到砍价",
          url: "/receiveBargain",
          ischildren: false,
        },
        {
          uid: 7,
          icon: "el-icon-pie-chart",
          name: "商家概况",
          url: "/general",
          ischildren: false,
        },
        {
          uid: 8,
          icon: "el-icon-truck",
          name: "车辆管理",
          url: "/car",
          ischildren: false,
        },
        {
          uid: 9,
          icon: "el-icon-alarm-clock",
          name: "预约看车",
          url: "/appoint",
          ischildren: false,
        },








        // {
        //   uid: 10,
        //   icon: "el-icon-document-add",
        //   name: "个人信息",
        //   url: "/info",
        //   ischildren: false,
        // },
        // {
        //   uid: 10,
        //   icon: "el-icon-document-add",
        //   name: "在线下单",
        //   url: "/order/place",
        //   ischildren: false,
        // },
        // {
        //   uid: 2,
        //   icon: "el-icon-document",
        //   name: "我的订单",
        //   url: "2",
        //   ischildren: true,
        //   children: [
        //     {
        //       uid: 3,
        //       icon: "el-icon-location",
        //       name: "我的订单",
        //       url: "/order/list",
        //     },
        //     {
        //       uid: 4,
        //       icon: "el-icon-location",
        //       name: "货物追踪",
        //       url: "/trace",
        //     },
        //   ],
        // },
        // {
        //   uid: 5,
        //   icon: "el-icon-setting",
        //   name: "账号管理",
        //   url: "3",
        //   ischildren: true,
        //   children: [
        //     {
        //       uid: 6,
        //       icon: "el-icon-location",
        //       name: "个人信息",
        //       url: "/info",
        //     },
        //     {
        //       uid: 7,
        //       icon: "el-icon-location",
        //       name: "银行卡管理",
        //       url: "/bank",
        //     },
        //     {
        //       uid: 8,
        //       icon: "el-icon-location",
        //       name: "地址列表",
        //       url: "/address",
        //     },
        //   ],
        // },
      ],
    };
  },
  methods: {
    // 接收顶部切换折叠
    receiveData(e) {
      this.isCollapse = e;
    },
    goToIndex() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style lang="scss" scoped>
.x-tops {
  height: 100vh;
  background: #F18700;
  overflow: hidden;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 230px;
  text-align: left;
}
/deep/.el-menu-item.is-active {
  border-left: 4px solid #409EFF;
  background: #AD6406 !important;
}
/deep/.el-menu-item i{
  color: #fff;
}
.logo-img {
  width: 104.5px;
  height: 50px;
  margin: 0px auto;
  cursor: pointer;
}
.x-title {
  height: 50px;
  width: 100%;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  border: 1px solid #AD6406;
  span {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
      Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif,
      Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  }
}
</style>
