<template>
  <el-container>
    <el-header>
      <Top></Top>
    </el-header>
    <el-main>
      <keep-alive v-if="isKeepAlive">
        <router-view></router-view>
      </keep-alive>
      <router-view v-else></router-view>
    </el-main>
    <el-footer>
      <Footer></Footer>
    </el-footer>
    <!-- 提示 -->
    <!-- <div class="tips">
      <Tip></Tip>
    </div> -->
  </el-container>
</template>

<script>
import Top from "./top";
import Footer from "./footer";
import Tip from "@/components/tip";
export default {
  components: {
    Top,
    Footer,
    Tip,
  },
  data() {
    return {
      isKeepAlive: true,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path === "/detail") {
        this.isKeepAlive = true;
      } else {
        if (from.path === "/detail") {
          this.isKeepAlive = true;
        } else {
          this.isKeepAlive = false;
          setTimeout(() => {
            this.isKeepAlive = true;
          }, 200);
        }
      }
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.el-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.el-header {
  height: 82px !important;
  padding: 0 !important;
  flex-shrink: 0;
}
.el-footer {
  height: 300px !important;
  padding: 0 !important;
  flex-shrink: 0;
}
.el-main {
  // background-color: #E9EEF3;
  background-color: #fff;
  color: #333;
  text-align: center;
  padding: 0 !important;
  flex: 1;
}
.tips {
  position: fixed;
  right: 0px;
  bottom: 140px;
}
</style>
