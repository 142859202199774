<!--防洪体系-->
<template>
  <div>
    <div class="search-form" style="text-align: left">
      <el-form
        ref="searchForm"
        :inline="true"
        :model="searchForm"
        label-width="70px"
        label-position="left"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="创建时间" prop="materielNameResult">
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input
            clearable
            size="small"
            v-model="searchForm.order_num"
            placeholder="请输入订单号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 模拟列表 -->
    <div class="tb-box">
      <div
        class="tb-item"
        v-for="(item, index) in dataList"
        :key="index"
        @click="handleDetail(item)"
      >
        <div class="tb-item-left">
          <el-image :src="item.car.exterior_images[0]" class="tb-item-img" fit="contain"></el-image>
        </div>
        <div class="tb-item-mid" style="padding: 3px 10px 3px 20px">
          <span class="tb-item-titles">
            <span class="tb-item-names"
              >{{ item.car.car_name }}</span
            >
            <span class="tb-item-nums">订单号：{{ item.order_num }}</span>
          </span>
          <span class="tb-item-price">{{ item.car_money / 10000 }} 万</span>
        </div>
        <div class="tb-item-right">
          <span class="tb-item-pay" v-if="activeName == 0 || activeName == 4">{{ orderStatus(item) }}</span>
        </div>
      </div>
      <el-empty description="暂无订单信息~" v-if="dataList.length == 0"></el-empty>
    </div>
    <div class="pagization-box">
      <CustomPagization
        :background="true"
        :currentPage="current"
        :total="total"
        :pageSize="size"
        @current-change="currentChange"
      />
    </div>
    <!-- 详情 -->
    <Detail ref="detail" :detail="detail" @returnMes="detailReturnMes" :listType='listType'></Detail>
  </div>
</template>

<script>
import { indexUserOrder, indexStoreOrder } from "@/api";
import Detail from "./detail.vue";
export default {
  components: {
    Detail,
  },
  props: {
    activeName: {
      type: [Number, String],
      default: 0,
    },
    listType: {
      type: Number,
      default: 1, // 1为用户  2为商家
    },
  },
  data() {
    return {
      detail: {},
      formVisible: false,
      record: {},
      searchForm: {
        createtime_begin: "",
        createtime_end: "",
        order_num: "",
      },
      value1: [],
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
    };
  },
  computed: {
    // 获取名称
    orderStatus() {
      return (item) => {
        if (item.pay_zt == 0) {
          return "待支付";
        } else if (item.pay_zt == 1) {
          return "待提车";
        } else if (item.pay_zt == 2) {
          return "已完成";
        } else if (item.pay_zt == 3) {
          return "已取消";
        }
      };
    },
  },
  created() {
    this.getInit();
  },
  methods: {
    // 打开详情
    handleDetail(item) {
      this.detail = item;
      this.$refs.detail.openDialog();
    },
    // 详情返回信息
    detailReturnMes() {
      this.detail = {};
      this.getInit();
    },
    // 获取列表
    async getInit() {
      let res =
        this.listType == 1
          ? await indexUserOrder({
              u_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
              page: this.current,
              limit: this.size,
              createtime_begin: this.value1 && this.value1.length > 0 ? this.value1[0] : "",
              createtime_end: this.value1 && this.value1.length > 0 ? this.value1[1] : "",
              order_num: this.searchForm.order_num,
              pay_zt: this.activeName,
            })
          : await indexStoreOrder({
              store_id: window.localStorage.getItem("store_id"),
              page: this.current,
              limit: this.size,
              createtime_begin: this.value1.length > 0 ? this.value1[0] : "",
              createtime_end: this.value1.length > 0 ? this.value1[1] : "",
              order_num: this.searchForm.order_num,
              pay_zt: this.activeName,
            });
      if (res.code == 1) {
        this.$emit("changelist", res.count);
        this.dataList = res.data.data;
        this.total = res.data.total;
      }
    },
    //视频监控站点修改
    handlePay(record) {
      this.formVisible = true;
      this.record = record;
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getInit();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {};
      this.value1 = [];
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.getInit();
    },
  },
};
</script>

<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
</style>
